import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  getEmailTemplates,
  addEmailTemplate,
  deleteEmailTemplate,
} from "../Email/emailSlice"
import { RootState } from "../../app/store"
import { store } from "../../app/store"
import * as Mui from "@mui/material"
import he from "he"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
/* eslint-disable react/display-name */

type AppDispatch = typeof store.dispatch

interface EmailTemplate {
  id?: any
  title: any
  subject: any
  content: any
}

const EmailTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const emailTemplates = useSelector(
    (state: RootState) => state.email.templates
  )
  const [htmlCode, setHtmlCode] = useState("")
  const [position, setPosition] = useState(0)
  const onEditEditorHTML = (e: any) => {
    const editorHTML = e.target.value
    setHtmlCode(editorHTML)
  }

  const [openModal, setOpenModal] = useState(false)
  const [newTemplate, setNewTemplate] = useState({
    id: "",
    content: "",
    fromName: "",
    subject: "",
    title: "",
  })

  useEffect(() => {
    dispatch(getEmailTemplates())
  }, [dispatch])

  const handleOpenModal = (data?: any) => {
    setNewTemplate(data)
    if (data?.id) {
      setHtmlCode(he.decode(data?.content))
    } else {
      setHtmlCode("")
      setNewTemplate({
        id: "",
        content: "",
        fromName: "",
        subject: "",
        title: "",
      })
    }

    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setNewTemplate((prevTemplate) => ({ ...prevTemplate, [name]: value }))
  }

  const handleDeleteTemplate = (templateId: any) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this template?"
    )
    if (!shouldDelete) {
      return
    }

    dispatch(deleteEmailTemplate(templateId))
      .unwrap()
      .then(() => {
        console.log("Template deleted successfully")
      })
      .catch((error) => {
        console.error("Error deleting template:", error.message)
      })
  }

  const handleAddTemplate = () => {
    const newTemplateWithoutId: EmailTemplate = {
      id: newTemplate.id,
      content: he.encode(htmlCode),
      subject: newTemplate.subject,
      title: newTemplate.title,
    }
    dispatch(addEmailTemplate(newTemplateWithoutId))

    setNewTemplate({
      id: "",
      content: "",
      fromName: "",
      subject: "",
      title: "",
    })
    handleCloseModal()
  }

  const theme = createTheme()
  const DrawerHeader = Mui.styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  }))

  const columns = [
    { name: "Title", options: { filter: false } },
    { name: "Subject", options: { filter: false } },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleOpenModal(emailTemplates[dataIndex])}
            >
              Edit
            </Mui.Button>
          )
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleDeleteTemplate(emailTemplates[dataIndex].id)}
            >
              Delete
            </Mui.Button>
          )
        },
      },
    },
  ]
  const options: MUIDataTableOptions = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
  }

  let data: (object | string[] | number[])[] = []
  if (emailTemplates) {
    data = emailTemplates.map((result: EmailTemplate) => [
      result.title,
      result.subject,
      result.content,
    ])
  }
  const previewInPopup = () => {
    const popupWidth = 600
    const popupHeight = 800
    const popupFeatures = `width=${popupWidth},height=${popupHeight},resizable=yes,scrollbars=yes`
    const newWindow = window.open("", "_blank", popupFeatures)
    newWindow.document.write(htmlCode)
  }
  const placeholderOptions = [
    { text: "First Name", value: "~firstName~" },
    { text: "Last Name", value: "~lastName~" },
    { text: "Vehicle List", value: "~vehicle_list~" },
    { text: "Quote ID", value: "~quote_id~" },
    { text: "Origin City", value: "~origin_city~" },
    { text: "Origin State", value: "~origin_state~" },
    { text: "Origin Zip", value: "~origin_zip~" },
    { text: "Destination City", value: "~destination_city~" },
    { text: "Destination State", value: "~destination_state~" },
    { text: "Destination Zip", value: "~destination_state~" },
    { text: "Vehicle Inop", value: "~vehicle_inop~" },
    { text: "Carrier Type", value: "~carrier_type~" },
    { text: "Available Date", value: "~available_date~" },
    { text: "Price", value: "~price~" },
    { text: "Order Source", value: "~order_source~" },
    { text: "Texting Shortlink", value: "~texting_shortlink~" },
    { text: "Agent Name", value: "~my_name~" },
    { text: "Agent Lastname", value: "~my_lastname~" },
    { text: "Agent Email", value: "~my_email~" },
    { text: "Agent Mobile", value: "~my_mobile~" },
  ]

  const handleChangeSelect = (event: any) => {
    const ss = htmlCode

    const startText = ss.substring(0, position)
    const endText = ss.substring(position, ss.length)
    const newText = startText + event.target.value + endText

    setHtmlCode(newText)
  }

  const handleClick = (event: any) => {
    const clickedPosition = event.target.selectionStart
    setPosition(clickedPosition)
  }

  return (
    <ThemeProvider theme={theme}>
      <DrawerHeader />
      <div>
        <Mui.Button
          type="button"
          variant="contained"
          className="button"
          onClick={() => {
            handleOpenModal()
          }}
          style={{ marginBottom: 20 }}
        >
          Add Template
        </Mui.Button>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px", // Set your width here
              },
            },
          }}
        >
          <DialogTitle>
            {newTemplate.id ? "Edit" : "Add"} Email Template
          </DialogTitle>
          <DialogContent>
            <form>
              <div className="InsertMailHead">
                <TextField
                  label="Title"
                  type="text"
                  name="title"
                  value={newTemplate.title}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  label="Subject"
                  type="text"
                  name="subject"
                  value={newTemplate.subject}
                  onChange={handleChange}
                  fullWidth
                />

                <Select onChange={handleChangeSelect} displayEmpty fullWidth>
                  <MenuItem value="" disabled>
                    Select Custom Field
                  </MenuItem>
                  {placeholderOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  label="Html Code"
                  multiline
                  rows={15}
                  value={htmlCode}
                  onChange={onEditEditorHTML}
                  fullWidth
                  onClick={(e) => {
                    handleClick(e)
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={previewInPopup}>Preview in New Window</Button>
            <Button onClick={handleCloseModal}>Close</Button>
            <Button onClick={handleAddTemplate}>
              {newTemplate.id ? "Edit" : "Add"} Template
            </Button>
          </DialogActions>
        </Dialog>

        <MUIDataTable
          title={"Templates List"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </ThemeProvider>
  )
}

export default EmailTable
