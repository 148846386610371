import React, { useState } from "react"
import * as Mui from "@mui/material"
import { addUser, editUser } from "../../containers/Login/userSlice"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../app/store"

interface UserProp {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  maxDailyLeadClaim: string
  smsProviderId: string
  password: string
  isSuperAdmin: boolean
}

const AddUserForm = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const [user, setUser] = useState<UserProp>(props.user)
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target
    setUser({ ...user, [id]: value })
  }
  const handleSave = () => {
    props.user.id ? dispatch(editUser(user)) : dispatch(addUser(user))
  }
  const handleSelectbox = (event: any) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  return (
    <form>
      <Mui.DialogContent>
        <Mui.Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Mui.TextField
              id="firstName"
              label="First Name"
              defaultValue={user.firstName}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="lastName"
              label="Last Name"
              defaultValue={user.lastName}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="emailAddress"
              label="emailAddress"
              defaultValue={user.emailAddress}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="phoneNumber"
              label="phone number"
              defaultValue={user.phoneNumber}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="maxDailyLeadClaim"
              label="Max Daily Lead Claim"
              defaultValue={user.maxDailyLeadClaim}
              onChange={handleInputChange}
            />
            <Mui.TextField
              id="smsProviderId"
              label="Sms Provider Id"
              defaultValue={user.smsProviderId}
              onChange={handleInputChange}
            />
            <Mui.FormControl sx={{ m: 1, minWidth: 224 }}>
              <Mui.InputLabel id="simple-select-helper-label">
                Admin User
              </Mui.InputLabel>
              <Mui.Select
                labelId="simple-select-helper-label"
                name="isSuperAdmin"
                defaultValue={user.isSuperAdmin}
                label="isSuperAdmin"
                onChange={handleSelectbox}
              >
                <Mui.MenuItem value={"true"}>True</Mui.MenuItem>
                <Mui.MenuItem value={"false"}>False</Mui.MenuItem>
              </Mui.Select>
            </Mui.FormControl>
            <Mui.TextField
              type="password"
              id="password"
              label="User Password"
              onChange={handleInputChange}
            />
          </div>
          <Mui.Button variant="contained" type="submit" onClick={handleSave}>
            Save
          </Mui.Button>
        </Mui.Box>
      </Mui.DialogContent>
    </form>
  )
}

export default AddUserForm
