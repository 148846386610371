import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { Lead, LeadsProp } from "../../types"
import { RootState } from "../../app/store"

interface State {
  leads: Lead[]
  status: string
  error: string
  followUps: Lead[]
  leadsCounts: number
  reports: []
}

const initialState: State = {
  leads: [],
  status: "idle",
  error: "",
  followUps: [],
  leadsCounts: 0,
  reports: [],
}

export const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    addLeadsState: (state, action) => {
      state.leads = [...action.payload, ...state.leads]
    },
    updateLeadsState: (state, action) => {
      const index = state.leads.findIndex(
        (lead) => lead.id === action.payload.id
      )
      const newLeads = [...state.leads]
      newLeads[index] = action.payload
      state.leads = newLeads
    },
    resetState: (state) => {
      state.status = "idle"
    },
    addNoteToLead: (state, action) => {
      const index = state.leads.findIndex(
        (lead) => lead.id === action.payload.lead
      )

      if (index !== -1) {
        // Check if the lead is found
        const newLeads = [...state.leads]
        const leadNotes = newLeads[index].notes || []
        newLeads[index].notes = [...leadNotes, action.payload.note] // Assuming action.payload.note is the new note
        state.leads = newLeads
      } else {
        console.error(`Lead with id ${action.payload.lead} not found.`)
      }
    },
    addHistoryToLead: (state, action) => {
      const index = state.leads.findIndex(
        (lead) => lead.id === action.payload.lead
      )
      const newLeads = [...state.leads]
      if (!newLeads[index]) return
      const leadHistories = newLeads[index].histories || []
      newLeads[index].histories = [...leadHistories, action.payload]
      state.leads = newLeads
    },
    GetHistoryandNotes: (state, action) => {
      const index = state.leads.findIndex(
        (lead) => lead.id === action.payload.id
      )
      const newLeads = [...state.leads]
      newLeads[index] = action.payload
      state.leads = newLeads
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.leads = action.payload
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
        state.leads = []
      })
      .addCase(updateLead.fulfilled, (state) => {
        state.status = "succeeded"
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
      })
      .addCase(getLead.fulfilled, (state) => {
        state.status = "succeeded"
      })
      .addCase(getLead.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
      })
      .addCase(addNote.fulfilled, (state) => {
        state.status = "succeeded"
      })
      .addCase(fetchFollowups.fulfilled, (state, action) => {
        state.followUps = action.payload
      })
      .addCase(getLeadsCount.fulfilled, (state, action) => {
        state.leadsCounts = action.payload
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.reports = action.payload
      })
      .addCase(calculatePrice.pending, (state) => {
        state.status = "loading"
      })
      .addCase(calculatePrice.fulfilled, (state, action) => {
        state.status = "succeeded"
        const leadIndex = state.leads.findIndex(
          (lead) => lead.id === action.meta.arg.leadId
        )
        if (leadIndex >= 0) {
          state.leads[leadIndex].finalPrice = action.payload
        }
      })
      .addCase(calculatePrice.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
      })
  },
})

export const selectAllLeads = (state: any) => state.leads.leads
export const selectLeadById = (
  state: any,
  id: Lead["id"]
): Lead | undefined => {
  const Us = state.leads.leads.find((lead: Lead) => lead.id === id)
  console.log("Us", Us)
  return Us
}

export const {
  addLeadsState,
  updateLeadsState,
  resetState,
  addNoteToLead,
  addHistoryToLead,
  GetHistoryandNotes,
} = leadSlice.actions

export const fetchLeads = createAsyncThunk(
  "leads/fetchLeads",
  async (data: LeadsProp) => {
    let url = `${process.env.REACT_APP_API_URL}/leads`
    const {
      isSelf,
      followUp,
      searchTerm,
      date,
      moveDate,
      statuss,
      currentUser,
      orangeStatus,
      EmailStatus,
    } = data
    if (isSelf) {
      url += `?isSelf=${isSelf}`
    }
    if (followUp) {
      url += `?followUp=${followUp}`
    }
    if (typeof searchTerm == "number") {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}page=${searchTerm}`
    }
    // TODO: refactor params
    if (searchTerm && typeof searchTerm !== "number") {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}search=${searchTerm}`
    }
    if (currentUser) {
      if (currentUser != "0") {
        const q = url.includes("?") ? "&" : "?"
        url += `${q}user=${currentUser}`
      }
    }

    if (statuss) {
      if (statuss != "0") {
        const q = url.includes("?") ? "&" : "?"
        url += `${q}status=${statuss}`
      }
    }
    if (orangeStatus) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}orangeStatus=${orangeStatus}`
    }
    if (EmailStatus) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}EmailStatus=${EmailStatus}`
    }
    if (date) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}date=${date}`
    }
    if (moveDate) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}moveDate=${moveDate}`
    }
    try {
      // TODO: make axios wrapper which has credantial settings
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      // TODO: error handling
      console.error(err)
      throw err
    }
  }
)

export const selectAllFollowUps = (state: any) => state.leads.followUps
export const fetchFollowups = createAsyncThunk(
  "leads/fetchFollowups",
  async () => {
    const url = `${process.env.REACT_APP_API_URL}/followups`
    try {
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      // TODO: error handling
      console.error(err)
      throw err
    }
  }
)

export const selectLeadsCount = (state: any) => state.leads.leadsCounts
export const getLeadsCount = createAsyncThunk(
  "leads/getLeadsCount",
  async (data: LeadsProp) => {
    let url = `${process.env.REACT_APP_API_URL}/leads-count`
    const {
      isSelf,
      followUp,
      searchTerm,
      date,
      moveDate,
      statuss,
      currentUser,
      orangeStatus,
      EmailStatus,
    } = data
    if (isSelf) {
      url += `?isSelf=${isSelf}`
    }
    if (followUp) {
      url += `?followUp=${followUp}`
    }
    if (typeof searchTerm == "number") {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}page=${searchTerm}`
    }
    // TODO: refactor params
    if (searchTerm && typeof searchTerm !== "number") {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}search=${searchTerm}`
    }
    if (currentUser) {
      if (currentUser != "0") {
        const q = url.includes("?") ? "&" : "?"
        url += `${q}user=${currentUser}`
      }
    }
    if (statuss) {
      if (statuss != "0") {
        const q = url.includes("?") ? "&" : "?"
        url += `${q}status=${statuss}`
      }
    }
    if (orangeStatus) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}orangeStatus=${orangeStatus}`
    }
    if (EmailStatus) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}EmailStatus=${EmailStatus}`
    }
    if (date) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}date=${date}`
    }
    if (moveDate) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}moveDate=${moveDate}`
    }
    try {
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      // TODO: error handling
      console.error(err)
      throw err
    }
  }
)

// Before Code by Utku Halis
// export const selectLeadsCount = (state: any) => state.leads.leadsCounts
// export const getLeadsCount = createAsyncThunk(
//   'leads/getLeadsCount',
//   async () => {
//     const url = `${process.env.REACT_APP_API_URL}/leads-count`
//     try {
//       const response = await axios.get(url, {
//         withCredentials: true,
//       })
//       return response.data
//     } catch (err) {
//       // TODO: error handling
//       console.error(err)
//       throw err
//     }
//   }
// )

export const updateLead = createAsyncThunk(
  "leads/updateLead",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/leads/${data.leadId}`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)
export const getLead = createAsyncThunk(
  "leads/getLead",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/leads/${data.leadId}`,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const addNote = createAsyncThunk(
  "notes/addNote",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/notes`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const selectAllReports = (state: any) => state.leads.reports
export const fetchReports = createAsyncThunk(
  "reports/getReportsList",
  async (data: LeadsProp) => {
    let url = `${process.env.REACT_APP_API_URL}/report/agent`
    const { startDate, endDate } = data

    if (startDate) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}startDate=${startDate}`
    }
    if (endDate) {
      const q = url.includes("?") ? "&" : "?"
      url += `${q}endDate=${endDate}`
    }

    try {
      // TODO: make axios wrapper which has credantial settings
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      // TODO: error handling
      console.error(err)
      throw err
    }
  }
)

export const sendMail = createAsyncThunk(
  "notification/mail",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-mail`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const sendSms = createAsyncThunk(
  "notification/sms",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-sms`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const calculatePrice = createAsyncThunk(
  "leads/calculatePrice",
  async (params: Record<string, any>) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/calculate`,
        {
          params: params,
          withCredentials: true,
        }
      )
      return response.data.finalPrice
    } catch (err: any) {
      throw err.response.data.message
    }
  }
)
export const selectLoadingStatus = (state: RootState) =>
  state.leads.status === "loading"

export default leadSlice.reducer
