import React from "react"
import { store } from "../../app/store"
import * as Mui from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { createTheme } from "@mui/material/styles"
import { useEffect } from "react"
import "./index.scss"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import { fetchReports, selectAllReports } from "../Leads/leadSlice"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import dayjs from "dayjs"

type AppDispatch = typeof store.dispatch
const theme = createTheme()
const DrawerHeader = Mui.styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}))

export default function Reports() {
  const reports = useSelector(selectAllReports)
  let data = []
  const dispatch = useDispatch<AppDispatch>()

  const prev = dayjs().subtract(1, "month").format("MM-DD-YYYY")

  const now = dayjs().format("MM-DD-YYYY")

  const [searchStartDate, setSearchStartDate] = React.useState<string | null>(
    prev
  )
  const [searchEndDate, setSearchEndDate] = React.useState<string | null>(now)
  useEffect(() => {
    if (searchStartDate && searchEndDate) {
      dispatch(
        fetchReports({ startDate: searchStartDate, endDate: searchEndDate })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStartDate, searchEndDate])

  const columns = [
    { name: "Agent Name", options: { filter: false } },
    { name: "Claimed ", options: { filter: false } },
    { name: "Booked ", options: { filter: false } },
    { name: "Hot ", options: { filter: false } },
    { name: "Cancelled ", options: { filter: false } },
    { name: "Booked Percentage", options: { filter: false } },
    { name: "Cancellation Ratio ", options: { filter: false } },
    { name: "Conversion Ratio", options: { filter: false } },
  ]
  const options: MUIDataTableOptions = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
  }

  const handleStartDate = (newValue: Dayjs | null) => {
    if (newValue) {
      setSearchStartDate(newValue.format("MM-DD-YYYY"))
    }
  }

  const handleEndDate = (newValue: Dayjs | null) => {
    if (newValue) {
      setSearchEndDate(newValue.format("MM-DD-YYYY"))
    }
  }
  /*TODO ask better way */
  const total = {
    agent: 0,
    totalLeadCount: 0,
    totalBookedCount: 0,
    totalHotCount: 0,
    totalCancelledCount: 0,
    bookingPercentage: 0,
    cancellationRatio: 0,
    conversionRatio: 0,
  }
  if (reports) {
    data = reports.map(
      (result: {
        agent: any
        bookingPercentage: any
        cancellationRatio: any
        conversionRatio: any
        totalLeadCount: any
        totalBookedCount: any
        totalHotCount: any
        totalCancelledCount: any
      }) => [
        result.agent,
        result.totalLeadCount,
        result.totalBookedCount,
        result.totalHotCount,
        result.totalCancelledCount,
        result.bookingPercentage,
        result.cancellationRatio,
        result.conversionRatio,
        (total.agent = total.agent + 1),
        (total.totalLeadCount = total.totalLeadCount + result.totalLeadCount),
        (total.totalBookedCount =
          total.totalBookedCount + result.totalBookedCount),
        (total.totalHotCount = total.totalHotCount + result.totalHotCount),
        (total.bookingPercentage =
          total.bookingPercentage + Number(result.bookingPercentage)),
        (total.conversionRatio =
          total.conversionRatio + Number(result.conversionRatio)),
      ]
    )
    data.push([
      "TOTAL",
      total.totalLeadCount,
      total.totalBookedCount,
      total.totalHotCount,
      total.totalCancelledCount,
      (
        (total.bookingPercentage =
          total.totalBookedCount / total.totalLeadCount) * 100
      ).toFixed(2),
      total.cancellationRatio,
      (
        (total.conversionRatio =
          total.totalBookedCount /
          (total.totalHotCount + total.totalBookedCount)) * 100
      ).toFixed(2),
    ])
  }

  return (
    <>
      <Mui.ThemeProvider theme={theme}>
        <DrawerHeader />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Report Start Date"
            inputFormat="MM/DD/YYYY"
            value={searchStartDate}
            onChange={handleStartDate}
            renderInput={(params) => <Mui.TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Report End Date"
            inputFormat="MM/DD/YYYY"
            value={searchEndDate}
            onChange={handleEndDate}
            renderInput={(params) => <Mui.TextField size="small" {...params} />}
          />
        </LocalizationProvider>

        <MUIDataTable
          title={"Reports"}
          data={data}
          columns={columns}
          options={options}
        />
      </Mui.ThemeProvider>
    </>
  )
}
