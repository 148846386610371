import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import * as Icons from "@mui/icons-material"

type Props = {
  message: string
}

const NotFound = (props: Props) => {
  const { message } = props

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <div style={{ textAlign: "center" }}>
          <Icons.Info fontSize="large" />
          <Typography variant="body1">{message}</Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default NotFound
