import { ReactElement, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "../../app/store"

interface Props {
  children: ReactElement
}
/*TODO need to change */
const ProtectedRoute = (props: Props) => {
  const { auth } = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    if (!auth) {
      navigate("/login")
    }
  }, [navigate, auth])

  return props.children
}

export { ProtectedRoute }
