import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { Reclaim } from "../../types"

interface State {
  reclaims: Reclaim[] | null
  statusReclaim: string
  messageReclaim: string
}

const initialState: State = {
  reclaims: null,
  statusReclaim: "idle",
  messageReclaim: "",
}

export const reclaimSlice = createSlice({
  name: "reclaims",
  initialState,
  reducers: {
    resetReclaimState: (state) => {
      state.statusReclaim = "idle"
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getReclaimList.pending, (state) => {
        state.statusReclaim = "loading"
      })
      .addCase(getReclaimList.fulfilled, (state, action) => {
        state.statusReclaim = "succeeded"
        state.reclaims = action.payload
      })
      .addCase(getReclaimList.rejected, (state, action) => {
        state.statusReclaim = "failed"
        state.messageReclaim = action.error.message || ""
        state.reclaims = []
      })
      .addCase(addReclaim.pending, (state) => {
        state.statusReclaim = "loading"
      })
      .addCase(addReclaim.fulfilled, (state, action) => {
        state.statusReclaim = "succeeded"
        alert("Reclaim request has been sent to Admin")
        /*TODO move email on state.messageReclaim*/
        // state.messageReclaim = 'Reclaim request has been sent to Admin'
        state.reclaims = [action.payload]
      })
      .addCase(addReclaim.rejected, (state, action) => {
        state.statusReclaim = "failed"
        state.messageReclaim = action.error.message || ""
      })
      .addCase(updateReclaim.fulfilled, (state, action) => {
        state.statusReclaim = "succeeded"
        //const index = state.reclaims.findIndex(
        //  (reclaim) => reclaim.id === action.payload.id
        //)
        //const newReclaims = [...state.reclaims]
        //newReclaims[index] = action.payload
        state.reclaims = action.payload
      })
      .addCase(updateReclaim.rejected, (state, action) => {
        state.statusReclaim = "failed"
        state.messageReclaim = action.error.message || ""
      })
  },
})

export const selectAllReclaims = (state: any) => state.reclaims.reclaims
export const { resetReclaimState } = reclaimSlice.actions

export const getReclaimList = createAsyncThunk(
  "reclaims/getReclaimList",
  async () => {
    const url = `${process.env.REACT_APP_API_URL}/reclaims`
    try {
      // TODO: make axios wrapper which has credantial settings
      const response = await axios.get(url, {
        withCredentials: true,
      })
      return response.data
    } catch (err) {
      // TODO: error handling
      console.error(err)
      throw err
    }
  }
)

export const addReclaim = createAsyncThunk(
  "reclaims/addReclaim",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reclaims`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const updateReclaim = createAsyncThunk(
  "leads/updateReclaim",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/reclaims/${data.reclaimId}`,
        data,
        {
          withCredentials: true,
        }
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export const deleteReclaim = createAsyncThunk(
  "leads/deleteReclaim",
  async (data: Record<string, any>) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/reclaims/${data.reclaimId}`
      )
      return response.data
    } catch (err: any) {
      // TODO: error handling
      throw err.response.data.message
    }
  }
)

export default reclaimSlice.reducer
