import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  getSmsTemplates,
  addSmsTemplate,
  deleteSmsTemplate,
} from "../Sms/smsSlice"
import { RootState } from "../../app/store"
import { store } from "../../app/store"
import * as Mui from "@mui/material"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

type AppDispatch = typeof store.dispatch

interface SmsTemplate {
  id?: any
  title: any
  content: any
}

const SmsList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const smsTemplates = useSelector((state: RootState) => state.sms.templates)

  useEffect(() => {
    dispatch(getSmsTemplates())
  }, [dispatch])

  const theme = createTheme()
  const DrawerHeader = Mui.styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  }))

  const handleChange = (event: any) => {
    const ss = selectedTemplate.content

    const startText = ss.substring(0, position)
    const endText = ss.substring(position, ss.length)
    const newText = startText + event.target.value + endText

    setSelectedTemplate((prev) => ({
      ...prev,
      content: newText,
    }))
  }

  const placeholderOptions = [
    { text: "First Name", value: "~firstName~" },
    { text: "Last Name", value: "~lastName~" },
    { text: "Vehicle List", value: "~vehicle_list~" },
    { text: "Quote ID", value: "~quote_id~" },
    { text: "Origin City", value: "~origin_city~" },
    { text: "Origin State", value: "~origin_state~" },
    { text: "Origin Zip", value: "~origin_zip~" },
    { text: "Destination City", value: "~destination_city~" },
    { text: "Destination State", value: "~destination_state~" },
    { text: "Destination Zip", value: "~destination_state~" },
    { text: "Vehicle Inop", value: "~vehicle_inop~" },
    { text: "Carrier Type", value: "~carrier_type~" },
    { text: "Available Date", value: "~available_date~" },
    { text: "Price", value: "~price~" },
    { text: "Order Source", value: "~order_source~" },
    { text: "Texting Shortlink", value: "~texting_shortlink~" },
    { text: "Agent Name", value: "~my_name~" },
    { text: "Agent Lastname", value: "~my_lastname~" },
    { text: "Agent Email", value: "~my_email~" },
    { text: "Agent Mobile", value: "~my_mobile~" },
  ]

  const columns = [
    { name: "Title", options: { filter: false } },
    { name: "Content", options: { filter: false } },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleOpenModal(smsTemplates[dataIndex])}
            >
              Edit
            </Mui.Button>
          )
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleDeleteTemplate(smsTemplates[dataIndex].id)}
            >
              Delete
            </Mui.Button>
          )
        },
      },
    },
  ]
  const options: MUIDataTableOptions = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
  }

  let data: (object | string[] | number[])[] = []
  if (smsTemplates) {
    data = smsTemplates.map((result: SmsTemplate) => [
      result.title,
      result.content,
    ])
  }

  const inputRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [position, setPosition] = useState(0)
  const [selectedTemplate, setSelectedTemplate] = useState<SmsTemplate | null>(
    null
  )

  const handleOpenModal = (template: SmsTemplate | null) => {
    setSelectedTemplate(template)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setSelectedTemplate(null)
    setOpenModal(false)
  }

  const handleSaveTemplate = (template: SmsTemplate) => {
    const newTemplateWithoutId: SmsTemplate = {
      id: template.id,
      content: template.content,
      title: template.title,
    }
    console.log(newTemplateWithoutId)
    dispatch(addSmsTemplate(newTemplateWithoutId))
    handleCloseModal()
  }

  const handleAddTemplate = () => {
    handleOpenModal({
      id: "", // veya null olarak ayarlayabilirsiniz
      title: "",
      content: "",
    })
  }
  const handleDeleteTemplate = (templateId: any) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this template?"
    )
    if (!shouldDelete) {
      return
    }

    dispatch(deleteSmsTemplate(templateId))
      .unwrap()
      .then(() => {
        console.log("Template deleted successfully")
      })
      .catch((error) => {
        console.error("Error deleting template:", error.message)
      })
  }

  const handleClick = (event: any) => {
    const clickedPosition = event.target.selectionStart
    setPosition(clickedPosition)
  }
  return (
    <ThemeProvider theme={theme}>
      <DrawerHeader />
      <div>
        <Mui.Button
          variant="contained"
          onClick={handleAddTemplate}
          style={{ marginBottom: 20 }}
        >
          Add Template
        </Mui.Button>
        <MUIDataTable
          title={"SMS Templates List"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>

      {/* Modal */}
      <Mui.Dialog open={openModal} onClose={handleCloseModal}>
        <Mui.DialogTitle>
          {selectedTemplate ? "Edit Template" : "Add Template"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <form>
            <div className="InsertMailHead">
              <Mui.TextField
                label="Title"
                type="text"
                name="title"
                value={selectedTemplate?.title || ""}
                onChange={(e) =>
                  setSelectedTemplate((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
                fullWidth
              />

              <Select onChange={handleChange} displayEmpty fullWidth>
                <MenuItem value="" disabled>
                  Select Placeholder
                </MenuItem>
                {placeholderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>

              <Mui.TextField
                inputProps={{ ref: inputRef }}
                label="Content"
                type="text"
                name="content"
                value={selectedTemplate?.content || ""}
                onChange={(e) =>
                  setSelectedTemplate((prev) => ({
                    ...prev,
                    content: e.target.value,
                  }))
                }
                onClick={(e) => {
                  handleClick(e)
                }}
                fullWidth
                multiline
                rows={6}
              />
            </div>
          </form>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseModal}>Cancel</Mui.Button>
          <Mui.Button onClick={() => handleSaveTemplate(selectedTemplate)}>
            Save
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </ThemeProvider>
  )
}

export default SmsList
