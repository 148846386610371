import { LeadStatus } from "../types"

export const leadStatus: LeadStatus[] = [
  {
    state: "unclaimed",
    color: "white",
    actionButtonText: "Claim",
    message: "None",
  },
  {
    state: "claimed",
    color: "gray",
    actionButtonText: "Reclaim",
    message: "Cold",
  },
  {
    state: "hot",
    color: "green",
    actionButtonText: "Reclaim",
    message: "Hot",
  },
  {
    state: "future",
    color: "yellow",
    actionButtonText: "Reclaim",
    message: "Future",
  },
  {
    state: "booked",
    color: "blue",
    actionButtonText: "Reclaim",
    message: "Booked",
  },
  {
    state: "bad",
    color: "red",
    actionButtonText: "Reclaim",
    message: "Bad Lead",
  },
  {
    state: "cancel",
    color: "black",
    actionButtonText: "Reclaim",
    message: "Cancel Lead",
  },
]
