/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { styled, alpha } from "@mui/material/styles"
import { getUser, userLogout } from "../../containers/Login/userSlice"
import { fetchLeads, getLeadsCount } from "../../containers/Leads/leadSlice"
import { AppDispatch, RootState } from "../../app/store"
import * as Mui from "@mui/material"
import * as Icons from "@mui/icons-material"
import "./index.scss"
import { Dayjs } from "dayjs"
import { useSocket } from "../../components/SocketProvider"

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

type Props = {
  open: boolean
  toggleDrawer: () => void
}

const drawerWidth = 250

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function Header(props: Props) {
  const { open, toggleDrawer } = props
  const { userInfo, auth } = useSelector((state: RootState) => state.user)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [searchDate, setSearchDate] = React.useState<Dayjs | null>(null)
  const [searchMoveDate, setSearchMoveDate] = React.useState<string | null>(
    null
  )
  const socket = useSocket()
  const location = useLocation()

  const dispatch = useDispatch<AppDispatch>()
  if (auth && !userInfo) {
    dispatch(getUser())
  }

  const logout = () => {
    dispatch(userLogout())
  }

  useEffect(() => {
    if (userInfo?.id) {
      console.log(userInfo)
      socket.on("deleteuser", (deletedUserId: string) => {
        console.log(userInfo)
        if (deletedUserId === userInfo?.id) {
          dispatch(userLogout())
        }
      })
    }
  }, [dispatch, socket, userInfo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // TODO: Bad usage. Use state instead checking path.
      let data = {}
      if (location.pathname === "/my-leads") data = { isSelf: true }
      if (location.pathname === "/follow-up") data = { followUp: true }
      let q = {}

      if (searchTerm !== undefined) {
        q = { searchTerm }
        localStorage.setItem("searchTerm", searchTerm)
      }
      if (searchDate) {
        q = { ...q, date: searchDate?.valueOf().toString() }
        localStorage.setItem("searchDate", searchDate?.valueOf().toString())
      }
      if (searchMoveDate) {
        q = { ...q, moveDate: searchMoveDate?.valueOf().toString() }
        localStorage.setItem(
          "searchMoveDate",
          searchMoveDate?.valueOf().toString()
        )
      }
      dispatch(fetchLeads({ ...data, ...q }))
      dispatch(getLeadsCount({ ...data, ...q }))
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchDate, searchMoveDate])

  return (
    <AppBar position="absolute" open={open}>
      <Mui.Toolbar
        sx={{
          pr: "20px",
        }}
      >
        <Mui.IconButton
          color="default"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <Icons.Menu />
        </Mui.IconButton>
      </Mui.Toolbar>
    </AppBar>
  )
}
