import * as Mui from "@mui/material"
import Notification from "../../components/Notification"
import { useDispatch, useSelector } from "react-redux"
import { createTheme } from "@mui/material/styles"
import {
  getReclaimList,
  selectAllReclaims,
  resetReclaimState,
  updateReclaim,
} from "../../containers/Admin/reclaimSlice"

import { useEffect, useState } from "react"
import "./index.scss"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import { Lead, User, Notes } from "../../types"
import LeadDetailTable from "../Leads/components/LeadDetailTable"
const theme = createTheme()
const DrawerHeader = Mui.styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}))
interface NoteProp {
  lead?: Lead
  isOpen: boolean
}
interface HistoryProp {
  lead?: Lead
  isOpen: boolean
}
export default function Reclaims() {
  let data = []
  const dispatch = useDispatch<any>()
  const reclaims = useSelector(selectAllReclaims)
  const { status, error } = useSelector((state: any) => state.reclaims)
  const [selectedLead, setSelectedLeadId] = useState<Lead | null>()

  const [openNotes, setOpenNotes] = useState<NoteProp>({
    isOpen: false,
  })

  const [openHistory, setOpenHistory] = useState<HistoryProp>({
    isOpen: false,
  })

  const [selectedLeadNote, setSelectedLeadNote] = useState<string>("0")
  const [fullWidth] = useState(true)
  const [midWidth] = useState<Mui.DialogProps["maxWidth"]>("md")

  const handleApprove = (rowId: string) => {
    const reclaim = reclaims[rowId]
    /*TODO make better */
    dispatch(
      updateReclaim({
        reclaimId: reclaim.id,
        status: "approved",
      })
    )
  }

  const handleReject = (rowId: string) => {
    const reclaim = reclaims[rowId]
    /*TODO make better */
    dispatch(
      updateReclaim({
        reclaimId: reclaim.id,
        status: "rejected",
      })
    )
  }

  const handleOpenNotes = (lead: any) => {
    setSelectedLeadId(reclaims[lead].lead)
    setOpenNotes({ isOpen: true })
    setSelectedLeadNote(lead.id)
  }
  const handleCloseNotes = () => {
    setOpenNotes({ isOpen: false, lead: undefined })
  }

  const handleOpenHistory = (lead: any) => {
    setSelectedLeadId(reclaims[lead].lead)
    setOpenHistory({ isOpen: true })
    setSelectedLeadNote(lead.id)
  }
  const handleCloseHistory = () => {
    setOpenHistory({ isOpen: false, lead: undefined })
  }

  useEffect(() => {
    if (status !== "loading") {
      dispatch(getReclaimList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { name: "Lead No", options: { filter: false } },
    { name: "Current Agent", options: { filter: false } },
    { name: "Request Agent", options: { filter: false } },
    { name: "Status", options: { filter: false } },
    {
      name: "History",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              size="small"
              onClick={() => handleOpenHistory(dataIndex)}
              color="success"
            >
              History
            </Mui.Button>
          )
        },
      },
    },
    {
      name: "History",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              size="small"
              onClick={() => handleOpenNotes(dataIndex)}
              color="primary"
            >
              Notes
            </Mui.Button>
          )
        },
      },
    },

    {
      name: "Approve",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="outlined"
              size="small"
              onClick={() => handleApprove(dataIndex)}
            >
              Approve
            </Mui.Button>
          )
        },
      },
    },

    {
      name: "Reject",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => handleReject(dataIndex)}
            >
              Reject
            </Mui.Button>
          )
        },
      },
    },
  ]
  const options: MUIDataTableOptions = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const dataIndex = rowMeta.dataIndex
      return (
        <tr>
          <td colSpan={columns.length + 2}>
            <LeadDetailTable lead={reclaims[dataIndex]?.lead} />
          </td>
        </tr>
      )
    },
  }

  /*TODO ask better way */
  if (reclaims) {
    data = reclaims.map(
      (result: {
        lead: Lead
        currentAgent: User
        requestAgent: User
        status: any
      }) => [
        result.lead.number,
        result.currentAgent?.firstName,
        result.requestAgent?.firstName,
        result.status,
      ]
    )
  }

  return (
    <>
      {status === "failed" && (
        <Notification
          error={error}
          onClick={() => {
            dispatch(resetReclaimState())
          }}
        ></Notification>
      )}

      <Mui.ThemeProvider theme={theme}>
        <DrawerHeader />

        <MUIDataTable
          title={"Reclaim List"}
          data={data}
          columns={columns}
          options={options}
        />

        <Mui.Dialog
          fullWidth={fullWidth}
          maxWidth={midWidth}
          open={openNotes.isOpen}
          onClose={handleCloseNotes}
        >
          <Mui.DialogTitle>Notes</Mui.DialogTitle>
          <Mui.DialogContent>
            <Mui.Box>
              <Mui.List className="notelist">
                {selectedLead &&
                  selectedLead.notes
                    ?.map((note: Notes) => (
                      <>
                        <Mui.ListItem key={note.id}>
                          <Mui.Grid container spacing={1} alignItems="center">
                            <Mui.Grid item md={9}>
                              <Mui.ListItemText primary={note.note} />
                            </Mui.Grid>
                            <Mui.Grid item md={3} textAlign="right">
                              <Mui.FormHelperText>
                                <Mui.Box>
                                  <b>{note.agent.firstName}</b>
                                </Mui.Box>
                                {new Date(note.updatedAt).toLocaleDateString()}{" "}
                                {new Date(note.updatedAt).toLocaleTimeString()}
                              </Mui.FormHelperText>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.ListItem>
                        <Mui.Divider />
                      </>
                    ))
                    .reverse()}
              </Mui.List>
            </Mui.Box>
          </Mui.DialogContent>
          <Mui.DialogActions>
            <Mui.Button onClick={handleCloseNotes}>Close</Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>

        {/* TODO: move to a component */}
        <Mui.Dialog
          fullWidth={fullWidth}
          maxWidth={midWidth}
          open={openHistory.isOpen}
          onClose={handleCloseHistory}
          PaperProps={{ style: { maxHeight: "70vh" } }}
        >
          <Mui.DialogTitle>History</Mui.DialogTitle>
          <Mui.DialogContent>
            <Mui.Box>
              <Mui.List className="historylist">
                {selectedLead &&
                  selectedLead.histories
                    ?.map((history) => (
                      <>
                        <Mui.ListItem key={history.id}>
                          <Mui.Grid container spacing={1} alignItems="center">
                            <Mui.Grid item md={9}>
                              <Mui.ListItemText primary={history.message} />
                            </Mui.Grid>
                            <Mui.Grid item md={3} textAlign="right">
                              <Mui.FormHelperText>
                                {new Date(
                                  history.updatedAt
                                ).toLocaleDateString()}{" "}
                                {new Date(
                                  history.updatedAt
                                ).toLocaleTimeString()}
                              </Mui.FormHelperText>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.ListItem>
                        <Mui.Divider />
                      </>
                    ))
                    .reverse()}
              </Mui.List>
            </Mui.Box>
          </Mui.DialogContent>
          <Mui.DialogActions>
            <Mui.Button onClick={handleCloseHistory}>Close</Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      </Mui.ThemeProvider>
    </>
  )
}
