import { useState } from "react"
import { store } from "../../app/store"
import * as Mui from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { createTheme } from "@mui/material/styles"
import {
  deleteUser,
  getUserList,
  selectAllUsers,
} from "../../containers/Login/userSlice"
import { useEffect } from "react"
import "./index.scss"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import AddUserForm from "./userForm"
import { User } from "../../types"
//import EditUserForm from './forms/EditUserForm'
import io from "socket.io-client"

type AppDispatch = typeof store.dispatch
const theme = createTheme()
const DrawerHeader = Mui.styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}))

export default function Users() {
  const [fullWidth] = useState(true)
  const [maxWidth] = useState<Mui.DialogProps["maxWidth"]>("md")
  const users = useSelector(selectAllUsers)
  let data = []
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    if (!users) {
      dispatch(getUserList())
    }

    // Socket.IO bağlantısı kur
    const socket = io(process.env.REACT_APP_API_URL, {
      path: "/socket.io",
    })

    socket.on("userLoggedIn", () => {
      dispatch(getUserList())
    })

    socket.on("userLoggedOut", () => {
      dispatch(getUserList())
    })

    // Cleanup on unmount
    return () => {
      socket.disconnect()
    }
  }, [dispatch, users])
  const [newUser, setNewUser] = useState({})
  const [openNewUser, setOpenNewUser] = useState(false)
  const [openDeleteUser, setOpenDeleteUser] = useState(false)
  const [deletedUser, setDeletedUser] = useState({})

  const handleOpenNewUser = () => {
    setNewUser({})
    setOpenNewUser(true)
  }
  const handleCloseNewUser = () => {
    setOpenNewUser(false)
  }
  const handleEditUser = (user: User) => {
    setNewUser(user)
    setOpenNewUser(true)
  }

  const handleOpenDeleteUser = (user: User) => {
    setDeletedUser(user)
    setOpenDeleteUser(true)
  }
  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false)
  }
  const handleSubmitDelete = () => {
    dispatch(deleteUser(deletedUser))
    handleCloseDeleteUser()
  }

  const columns = [
    {
      name: "Is Active",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          const isActive = users[dataIndex]?.isActive
          return (
            <div
              className="badge"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  background: isActive ? "#2e7d32" : "#d32f2f",
                  color: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                  borderRadius: 10,
                  width: 15,
                  height: 15,
                }}
              ></div>
            </div>
          )
        },
      },
    },
    {
      name: "FirstName  LastName",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          const FullName =
            users[dataIndex]?.firstName + " " + users[dataIndex]?.lastName
          const emailAddress = users[dataIndex]?.emailAddress
          return (
            <div>
              <div>
                <b>{FullName}</b>
              </div>
              <div>{emailAddress}</div>
            </div>
          )
        },
      },
    },
    { name: "Phone Number", options: { filter: false } },
    { name: "Max Daily Claim", options: { filter: false }, style: {} },
    { name: "Sms Provider Id", options: { filter: false } },
    { name: "Admin", options: { filter: false } },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleEditUser(users[dataIndex])}
            >
              Edit
            </Mui.Button>
          )
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleOpenDeleteUser(users[dataIndex])}
            >
              Delete
            </Mui.Button>
          )
        },
      },
    },
  ]
  const options: MUIDataTableOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "Is Active",
      direction: "desc",
    },
  }
  /*TODO ask better way */
  if (users) {
    data = users.map(
      (result: {
        isActive: any
        phoneNumber: any
        maxDailyLeadClaim: any
        smsProviderId: any
        isSuperAdmin: any
      }) => [
        result.isActive,
        result.phoneNumber,
        result.maxDailyLeadClaim ? result.maxDailyLeadClaim : "",
        result.smsProviderId,
        result.isSuperAdmin ? "yes" : "no",
      ]
    )
  }

  return (
    <>
      <Mui.ThemeProvider theme={theme}>
        <DrawerHeader />
        <Mui.Box sx={{ "& button": { m: 1 } }}>
          <Mui.Button
            type="button"
            variant="contained"
            className="button"
            onClick={() => {
              handleOpenNewUser()
            }}
          >
            Add New User
          </Mui.Button>
        </Mui.Box>
        <MUIDataTable
          title={"Employee List"}
          data={data}
          columns={columns}
          options={options}
        />
      </Mui.ThemeProvider>
      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openNewUser}
        onClose={handleCloseNewUser}
      >
        <Mui.DialogTitle>User Settings</Mui.DialogTitle>
        <Mui.DialogContent>
          <AddUserForm user={newUser} />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseNewUser}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      <Mui.Dialog
        fullWidth={fullWidth}
        open={openDeleteUser}
        onClose={handleCloseDeleteUser}
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {`Do you want to delete user?`}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            If you submit this from user will be delete do you agree ?
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseDeleteUser}>Close</Mui.Button>
          <Mui.Button onClick={handleSubmitDelete}>Submit</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  )
}
