import { useState } from "react"
import * as Mui from "@mui/material"
import * as Icons from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import logo from "../../assets/images/logo.png"
import Header from "../Header"
import { Link } from "react-router-dom"
import { getUser, userLogout } from "../../containers/Login/userSlice"
import { AppDispatch, RootState } from "../../app/store"
import { useDispatch, useSelector } from "react-redux"

const drawerWidth = 250
const Drawer = styled(Mui.Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

export default function Sidebar() {
  const [open, setOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const { userInfo, auth } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch<AppDispatch>()
  if (auth && !userInfo) {
    dispatch(getUser())
  }
  const logout = () => {
    dispatch(userLogout())
  }
  return (
    <>
      <Header open={open} toggleDrawer={toggleDrawer} />
      <Drawer variant="permanent" open={open}>
        <Mui.Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <img className="logo" src={logo} alt="safemile" width={180} />
          <Mui.IconButton onClick={toggleDrawer}>
            <Icons.ChevronLeft />
          </Mui.IconButton>
        </Mui.Toolbar>
        <Mui.Divider />
        <Mui.List component="nav">
          {/* TODO: Make dynamic */}
          <Link to={"/"}>
            <Mui.ListItem key={"Dashboard"} disablePadding>
              <Mui.ListItemButton>
                <Mui.ListItemIcon>
                  <Icons.Dashboard />
                </Mui.ListItemIcon>
                <Mui.ListItemText primary={"Dashboard"} />
              </Mui.ListItemButton>
            </Mui.ListItem>
          </Link>
          <Link to={"/my-leads"}>
            <Mui.ListItem key={"My Leads"} disablePadding>
              <Mui.ListItemButton>
                <Mui.ListItemIcon>
                  <Icons.AssignmentReturn />
                </Mui.ListItemIcon>
                <Mui.ListItemText primary={"My Leads"} />
              </Mui.ListItemButton>
            </Mui.ListItem>
          </Link>
          <Link to={"/follow-up"}>
            <Mui.ListItem key={"Follow Up"} disablePadding>
              <Mui.ListItemButton>
                <Mui.ListItemIcon>
                  <Icons.Bookmark />
                </Mui.ListItemIcon>
                <Mui.ListItemText primary={"Follow Up"} />
              </Mui.ListItemButton>
            </Mui.ListItem>
          </Link>
        </Mui.List>
        <Mui.Divider />
        {userInfo?.isSuperAdmin && (
          <>
            <Mui.List>
              <Link to={"/reports"}>
                <Mui.ListItem key={"Reports"} disablePadding>
                  <Mui.ListItemButton>
                    <Mui.ListItemIcon>
                      <Icons.BarChart />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={"Reports"} />
                  </Mui.ListItemButton>
                </Mui.ListItem>
              </Link>
              <Link to={"/reports"}>
                <Mui.ListItem key={"All Reports"} disablePadding>
                  <Mui.ListItemButton>
                    <Mui.ListItemIcon>
                      <Icons.AssignmentReturn />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={"All Reports"} />
                  </Mui.ListItemButton>
                </Mui.ListItem>
              </Link>
            </Mui.List>
            <Mui.Divider />
            <Link to={"/users"}>
              <Mui.ListItem key={"Users"} disablePadding>
                <Mui.ListItemButton>
                  <Mui.ListItemIcon>
                    <Icons.Person />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText primary={"Users"} />
                </Mui.ListItemButton>
              </Mui.ListItem>
            </Link>
            <Link to={"/reclaim-requests"}>
              <Mui.ListItem key={"reclaim-requests"} disablePadding>
                <Mui.ListItemButton>
                  <Mui.ListItemIcon>
                    <Icons.VerifiedUserSharp />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText primary={"Reclaim Requests"} />
                </Mui.ListItemButton>
              </Mui.ListItem>
            </Link>
            <Link to={"/mail-templates"}>
              <Mui.ListItem key={"mail-templates"} disablePadding>
                <Mui.ListItemButton>
                  <Mui.ListItemIcon>
                    <Icons.Mail />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText primary={"Mail Templates"} />
                </Mui.ListItemButton>
              </Mui.ListItem>
            </Link>
            <Link to={"/sms-templates"}>
              <Mui.ListItem key={"mail-templates"} disablePadding>
                <Mui.ListItemButton>
                  <Mui.ListItemIcon>
                    <Icons.Sms />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText primary={"Sms Templates"} />
                </Mui.ListItemButton>
              </Mui.ListItem>
            </Link>
          </>
        )}
        <Link to={"#"} onClick={() => logout()}>
          <Mui.ListItem key={"logout"} disablePadding>
            <Mui.ListItemButton>
              <Mui.ListItemIcon>
                <Icons.Logout />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={"Logout"} />
            </Mui.ListItemButton>
          </Mui.ListItem>
        </Link>
      </Drawer>
    </>
  )
}
