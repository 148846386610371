/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect } from "react"
import io from "socket.io-client"
import { AppDispatch } from "../app/store"
import { useDispatch } from "react-redux"
import { userActivity } from "../containers/Login/userSlice"

const socket = io(`${process.env.REACT_APP_API_URL}`, {
  path: "/socket.io",
  transports: ["polling"],
  reconnection: true,
  reconnectionAttempts: 10,
})

type SocketContextType = SocketIOClient.Socket | null

const SocketContext = createContext<SocketContextType>(null)

export const useSocket = (): SocketIOClient.Socket => {
  const socket = useContext(SocketContext)
  if (!socket) {
    throw new Error("useSocket must be used within a SocketProvider")
  }
  return socket
}

const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const logout = () => {
    dispatch(userActivity())
  }

  useEffect(() => {
    const INACTIVITY_TIMEOUT = 10 * 60 * 1000
    let timeout: NodeJS.Timeout

    const resetTimeout = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        socket.emit("userInactive")
        logout()
      }, INACTIVITY_TIMEOUT)
      localStorage.setItem("lastActivity", Date.now().toString())
    }

    const syncActivity = (event: StorageEvent) => {
      if (event.key === "lastActivity") {
        resetTimeout()
      }
    }

    const events = ["mousemove", "keydown", "click"]
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout)
    })

    window.addEventListener("storage", syncActivity)

    resetTimeout() // Başlangıçta zamanlayıcıyı başlat

    socket.on("userLoggedIn", (data: { userId: any }) => {
      console.log("Kullanıcı giriş yaptı:", data.userId)
    })

    socket.on("userLoggedOut", (data: { userId: any }) => {
      console.log("Kullanıcı çıkış yaptı:", data.userId)
    })

    return () => {
      clearTimeout(timeout)
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout)
      })
      window.removeEventListener("storage", syncActivity)
      socket.off("userLoggedIn")
      socket.off("userLoggedOut")
    }
  }, [])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export default SocketProvider
