import { configureStore } from "@reduxjs/toolkit"
import emailReducer from "../containers/Email/emailSlice"
import leadReducer from "../containers/Leads/leadSlice"
import userReducer from "../containers/Login/userSlice"
import reclaimReducer from "../containers/Admin/reclaimSlice"
import smsReducer from "../containers/Sms/smsSlice"
export const store = configureStore({
  reducer: {
    email: emailReducer,
    leads: leadReducer,
    user: userReducer,
    reclaims: reclaimReducer,
    sms: smsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
