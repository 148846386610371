import { createBrowserRouter } from "react-router-dom"
import Layout from "../containers/Layout"
import { ProtectedRoute } from "../containers/Layout/protectedRoute"
import Leads from "../containers/Leads"
import Login from "../containers/Login"
import Users from "../containers/Admin/users"
import Reclaims from "../containers/Admin/reclaims"
import Reports from "../containers/Admin/reports"
import MailTemplates from "../containers/Admin/mailTemplates"
import SmsTemplates from "../containers/Admin/smsTemplates"
// TODO: check the usage below
const route = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/", element: <Leads /> }],
  },
  {
    path: "/my-leads",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/my-leads", element: <Leads isSelf={true} /> }],
  },
  {
    path: "/follow-up",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/follow-up", element: <Leads followUp={true} /> }],
  },
  {
    path: "/login",
    element: (
      <ProtectedRoute>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: "/users",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/users", element: <Users /> }],
  },
  {
    path: "/reclaim-requests",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/reclaim-requests", element: <Reclaims /> }],
  },
  {
    path: "/reports",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/reports", element: <Reports /> }],
  },
  {
    path: "/mail-templates",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/mail-templates", element: <MailTemplates /> }],
  },
  {
    path: "/sms-templates",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: "/sms-templates", element: <SmsTemplates /> }],
  },
])

export default route
