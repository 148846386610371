export default function KeyboardTripleArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="318.793 24.831 18.175 12.028"
      width="20.542"
      height="12.028"
    >
      <path fill="none" />
      <path d="M 320.203 24.831 L 318.793 26.241 L 323.373 30.831 L 318.793 35.421 L 320.203 36.831 L 326.203 30.831 L 320.203 24.831 Z"></path>
      <path d="M 325.49 24.831 L 324.08 26.241 L 328.66 30.831 L 324.08 35.421 L 325.49 36.831 L 331.49 30.831 L 325.49 24.831 Z"></path>
      <path d="M 330.968 24.859 L 329.558 26.269 L 334.138 30.859 L 329.558 35.449 L 330.968 36.859 L 336.968 30.859 L 330.968 24.859 Z"></path>
    </svg>
  )
}
