import React, { useState, useEffect, useRef, LegacyRef } from "react"
import * as Mui from "@mui/material"
import * as Icons from "@mui/icons-material"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import ReactTimeAgo from "react-time-ago"
import { useSocket } from "../../components/SocketProvider"
import { useSelector, useDispatch } from "react-redux"
import { leadStatus } from "../../constants"
import { RootState } from "../../app/store"
import leadAlarmSound from "../../assets/images/alarm.mp3"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import {
  selectAllLeads,
  selectLeadsCount,
  getLeadsCount,
  fetchLeads,
  updateLead,
  addLeadsState,
  updateLeadsState,
  addNote,
  resetState,
  fetchFollowups,
  selectAllFollowUps,
  selectLeadById,
  addNoteToLead,
  addHistoryToLead,
  getLead,
  GetHistoryandNotes,
} from "./leadSlice"
import { getUserList, selectAllUsers } from "../../containers/Login/userSlice"
import { addReclaim, resetReclaimState } from "../Admin/reclaimSlice"
import { Lead, LeadsProp, LeadStatus, Notes, Vehicle } from "../../types"
import LeadDetail from "./components/LeadDetail"
import NotFound from "../../components/Info/notfound"
import GoogleMapsWithProps from "../../components/Maps"
import NotificationComp from "../../components/Notification"
import KeyboardTripleArrowRight from "../../assets/images/icon"
import map from "../../assets/images/safemile.png"
import EdiText from "react-editext"
import "./index.scss"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Tooltip from "@mui/material/Tooltip"
import { Dayjs } from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { styled, alpha } from "@mui/material/styles"
const DrawerHeader = Mui.styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}))

interface NoteProp {
  lead?: Lead
  isOpen: boolean
}
interface EmailHistoryProp {
  lead?: Lead
  isOpen: boolean
}
interface ReclaimProp {
  lead?: Lead
  isOpen: boolean
}
interface HistoryProp {
  lead?: Lead
  isOpen: boolean
}
TimeAgo.addDefaultLocale(en)
function phoneFormat(input: string) {
  //returns (###) ###-####
  input = input.replace(/\D/g, "")
  const size = input.length
  if (size > 0) {
    input = "(" + input
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4, 11)
  }
  if (size > 6) {
    input = input.slice(0, 9) + "-" + input.slice(9)
  }
  return input
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  marginRight: 10,
  color: "black",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}))

const StyledInputBase = styled(Mui.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}))

export default function Leads(props: LeadsProp) {
  const { isSelf, followUp } = props
  const socket = useSocket()
  // TODO: most of them will be deleted
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>()
  const users = useSelector(selectAllUsers)
  useEffect(() => {
    if (!users) {
      dispatch(getUserList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])
  const [fullWidth] = useState(true)
  const [maxWidth] = useState<Mui.DialogProps["maxWidth"]>("lg")
  const [midWidth] = useState<Mui.DialogProps["maxWidth"]>("md")
  const currentDate = new Date().toJSON().slice(0, 10)
  const [currentUser, setcurrentUser] = useState("0")
  const [statuss, setStatus] = useState("0")
  const [orangeStatus, setOrangeStatus] = useState(false)
  const [EmailStatus, setEmailStatus] = useState(false)
  const [loading, SetLoading] = useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [searchDate, setSearchDate] = React.useState<Dayjs | null>(null)
  const [searchMoveDate, setSearchMoveDate] = React.useState<string | null>(
    null
  )

  const clearSearchDate = () => {
    localStorage.removeItem("searchDate")
    setSearchDate(null)
  }

  const clearSearchMoveDate = () => {
    localStorage.removeItem("searchMoveDate")
    setSearchMoveDate(null)
  }

  const [openNotes, setOpenNotes] = useState<NoteProp>({
    isOpen: false,
  })
  const [openEmailHistory, setopenEmailHistory] = useState<EmailHistoryProp>({
    isOpen: false,
  })
  const [openReclaim, setOpenReclaim] = useState<ReclaimProp>({
    lead: undefined,
    isOpen: false,
  })
  const [openHistory, setOpenHistory] = useState<HistoryProp>({
    isOpen: false,
  })
  // For showing selected note content in popup
  // can be used for history etc.
  const [selectedLeadNote, setSelectedLeadNote] = useState<string>("0")
  const selectedLead: Lead = useSelector((state) =>
    selectLeadById(state, selectedLeadNote)
  )

  const [note, setNote] = useState("")
  const { userInfo } = useSelector((state: RootState) => state.user)
  const [openMap, setOpenMap] = useState(false)
  const [destinationCity, setDestination] = useState("")
  const [originCity, setOrigin] = useState("")
  const [webHooks, setWebhooks] = useState([])
  // TODO: replace 'any' type with data model
  const dispatch = useDispatch<any>()
  const leads = useSelector(selectAllLeads)
  const followUps = useSelector(selectAllFollowUps)
  const leadsCount = useSelector(selectLeadsCount)
  const { status, error } = useSelector((state: any) => state.leads)
  const { statusReclaim, messageReclaim } = useSelector(
    (state: any) => state.reclaims
  )
  const errorMessage = error || messageReclaim
  const isFailed = status === "failed" || statusReclaim === "failed"
  const handleChangePage = (event: any, value: any) => {
    openPage(value)
    setCurPage(value)
  }
  const handleChange = (newValue: Dayjs | null) => {
    if (newValue) {
      if (newValue.isValid()) {
        setSearchMoveDate(null)
        setSearchDate(newValue)
      }
    }
  }

  const handleChangeMoveDate = (newValue: Dayjs | null) => {
    if (newValue) {
      if (newValue.isValid()) {
        setSearchDate(null)
        setSearchMoveDate(newValue.format("YYYY-MM-DD HH:mm:ss"))
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        SetLoading(true)
        if (status !== "loading") {
          await dispatch(
            fetchLeads({
              isSelf,
              followUp,
              statuss,
              currentUser,
              orangeStatus,
              EmailStatus,
              searchTerm,
              date: searchDate?.valueOf().toString(),
              moveDate: searchMoveDate?.valueOf().toString(),
            })
          )
          await dispatch(
            getLeadsCount({
              statuss,
              currentUser,
              orangeStatus,
              EmailStatus,
              searchTerm,
              date: searchDate?.valueOf().toString(),
              moveDate: searchMoveDate?.valueOf().toString(),
            })
          )
          dispatch(fetchFollowups())
        }
      } finally {
        SetLoading(false)
      }
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSelf,
    followUp,
    statuss,
    currentUser,
    orangeStatus,
    EmailStatus,
    searchDate,
    searchMoveDate,
    searchTerm,
  ])

  useEffect(() => {
    socket.on("newLead", (leads: any) => {
      handlePlaySound()
      dispatch(addLeadsState(leads))
    })
    socket.on("leadUpdated", (lead: any) => {
      dispatch(updateLeadsState(lead))
    })
    socket.on("newNote", (note: any) => {
      dispatch(addNoteToLead(note))
    })
    socket.on("newHistory", (history: any) => {
      dispatch(addHistoryToLead(history))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeUser = (event: any) => {
    setcurrentUser(event.target.value)
  }
  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value)
  }
  const handleOrange = (event: any) => {
    setOrangeStatus(event.target.checked)
  }
  const handleEmail = (event: any) => {
    setEmailStatus(event.target.checked)
  }
  const handleClickOpenMap = () => {
    setOpenMap(true)
  }
  const handleCloseMap = () => {
    setOpenMap(false)
  }
  const handleOpenNotes = (lead: Lead) => {
    setOpenNotes({ isOpen: true })
    setSelectedLeadNote(lead.id)
  }
  const handleOpenEmails = (webhook: any) => {
    setopenEmailHistory({ isOpen: true })
    setWebhooks(webhook)
  }
  const handleCloseNotes = () => {
    setOpenNotes({ isOpen: false, lead: undefined })
    setNote("")
  }
  const handleCloseEmails = () => {
    setopenEmailHistory({ isOpen: false })
    setWebhooks([])
  }
  const handleSubmitNotes = async () => {
    console.log(selectedLead, note)
    const postData = { note, lead: selectedLead.id }
    await dispatch(addNote(postData))
    setNote("")
    //handleCloseNotes()
  }
  const handleOpenHistory = (lead: Lead) => {
    setOpenHistory({ isOpen: true })
    setSelectedLeadNote(lead.id)
  }
  const handleCloseHistory = () => {
    setOpenHistory({ isOpen: false, lead: undefined })
  }
  const handleOpenReclaim = (lead: any) => {
    setOpenReclaim({ isOpen: true, lead })
  }
  const handleCloseReclaim = () => {
    setOpenReclaim({ isOpen: false, lead: undefined })
  }
  const handleSubmitReclaim = () => {
    const postData = {
      lead: openReclaim.lead?.id,
      currentAgent: openReclaim.lead?.agent?.id,
      requestAgent: userInfo?.id,
    }
    /*User notifications shold be show*/
    /*TODO send email*/
    dispatch(addReclaim(postData))
    handleCloseReclaim()
    return
  }

  const handleUpdateOrangeStatus = (id: string, orangeStatus: boolean) => {
    dispatch(updateLead({ leadId: id, orangeStatus }))
  }
  const handlePlaySound = () => {
    new Notification("You have a new lead!")
    const sound: HTMLAudioElement = new Audio()
    sound.src = leadAlarmSound
    sound.load()
    sound.play()
  }

  const handleUpdateStatus = async (lead: Lead, status: string) => {
    const leadStatuses = await dispatch(updateLead({ leadId: lead.id, status }))
    if (!leadStatuses?.error?.message) {
      if (status === "bad") {
        setOpenNotes({ isOpen: true })
        setSelectedLeadNote(lead.id)
      }
    }
  }

  const handleUpdateFollowUp = (id: string, followUp: number | null) => {
    dispatch(updateLead({ leadId: id, followUp }))
  }
  const handleUpdateCall = (lead: Lead, calling: string, wrong: any) => {
    if (!wrong) {
      dispatch(updateLead({ leadId: lead.id, calling }))
    }
  }
  const getLeadNoteHistory = async (lead: Lead) => {
    const curlead = await dispatch(getLead({ leadId: lead.id }))
    dispatch(GetHistoryandNotes(curlead.payload))
  }

  const parentRef = useRef()

  const [curPage, setCurPage] = useState(1)

  const openPage = async (pageNum: any) => {
    try {
      SetLoading(true)

      const data = {
        isSelf,
        followUp,
        statuss,
        currentUser,
        orangeStatus,
        EmailStatus,
        searchTerm: pageNum,
        searchMoveDate: searchMoveDate,
      }

      let q = {}
      if (searchTerm !== undefined) {
        q = { searchTerm }
        localStorage.setItem("searchTerm", searchTerm)
      }
      if (searchDate) {
        q = { ...q, date: searchDate?.valueOf().toString() }
        localStorage.setItem("searchDate", searchDate?.valueOf().toString())
      }
      if (searchMoveDate) {
        q = { ...q, moveDate: searchMoveDate?.valueOf().toString() }
        localStorage.setItem(
          "searchMoveDate",
          searchMoveDate?.valueOf().toString()
        )
      }
      if (statuss) {
        q = { ...q, statuss: statuss }
      }

      if (currentUser) {
        q = { ...q, user: currentUser }
      }

      if (orangeStatus) {
        q = { ...q, orangeStatus: orangeStatus }
      }
      if (EmailStatus) {
        q = { ...q, EmailStatus: EmailStatus }
      }

      // Dispatch asynchronous actions
      await dispatch(fetchLeads({ ...data, ...q }))
      await dispatch(getLeadsCount({ ...data, ...q }))
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
      SetLoading(false)
    } catch (error) {
      // Handle errors here
      console.error("Error while fetching data:", error)
      SetLoading(false)
    }
  }
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }))
  return (
    <>
      {isFailed && (
        <NotificationComp
          error={errorMessage}
          onClick={() => {
            dispatch(resetState())
            dispatch(resetReclaimState())
          }}
        ></NotificationComp>
      )}
      <DrawerHeader />

      <Grid
        ref={scrollRef}
        container
        spacing={2}
        alignItems="normal"
        className="sabit"
      >
        <Grid item>
          <Select
            labelId="select-user-label"
            id="SelectUser"
            value={currentUser}
            label="Users"
            onChange={handleChangeUser}
          >
            <MenuItem value={"0"}>All User</MenuItem>
            {users &&
              users?.map((user: any) => (
                <MenuItem key={"User" + user?.id} value={user?.id}>
                  {`${user.firstName} ${user.lastName}`}{" "}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            labelId="select-status-label"
            id="SelectStatus"
            value={statuss}
            label="Status"
            onChange={handleChangeStatus}
          >
            <MenuItem value={"0"}>All Colors</MenuItem>
            {leadStatus
              .filter((l) => l.state !== "unclaimed")
              ?.map((leadState, idx) => (
                <MenuItem key={"Color" + idx} value={leadState.state}>
                  <div
                    className={`lead-select button status-${leadState.color}`}
                  />
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item>
          <Grid item>
            <FormControlLabel
              style={{ padding: 6 }}
              control={
                <Switch
                  name="orangeStatus"
                  onChange={handleOrange}
                  checked={orangeStatus}
                />
              }
              label="POSTED"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <FormControlLabel
              style={{ padding: 6 }}
              control={
                <Switch
                  name="orangeStatus"
                  onChange={handleEmail}
                  checked={EmailStatus}
                />
              }
              label="Email"
            />
          </Grid>
        </Grid>
        <Grid item className="gridItem" style={{ maxWidth: 200 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Lead Date"
              inputFormat="MM/DD/YYYY"
              value={searchDate}
              onChange={handleChange}
              renderInput={(params) => (
                <Mui.TextField size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <Mui.IconButton
            onClick={clearSearchDate}
            aria-label="delete"
            size="small"
          >
            <Icons.Delete fontSize="inherit" />
          </Mui.IconButton>
        </Grid>

        <Grid item className="gridItem" style={{ maxWidth: 200 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Move Date"
              inputFormat="MM/DD/YYYY"
              value={searchMoveDate}
              onChange={handleChangeMoveDate}
              className="test"
              renderInput={(params) => (
                <Mui.TextField size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <Mui.IconButton
            onClick={clearSearchMoveDate}
            aria-label="delete"
            size="small"
          >
            <Icons.Delete fontSize="inherit" />
          </Mui.IconButton>
        </Grid>
        <Grid item className="gridItem" style={{ maxWidth: 200 }}>
          <Search>
            <SearchIconWrapper>
              <Icons.Search />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearchDate(null)
                setSearchMoveDate(null)
                // eslint-disable-next-line no-useless-escape
                const target = e.target.value
                setSearchTerm(target.replace(/[( )+-]/g, ""))
              }}
            />
          </Search>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && !leads?.length && <NotFound message={"Lead not found."} />}
      <div
        key={"Leads"}
        ref={parentRef as unknown as LegacyRef<HTMLDivElement>}
        style={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          {!!followUps.length && (
            <Mui.Box className="followup-box">
              <b>Today Follow-ups: </b>
              {followUps?.map((f: Lead) => (
                <span
                  key={"Follow" + f.id}
                  onClick={() => {
                    dispatch(fetchLeads({ searchTerm: f.number }))
                    dispatch(getLeadsCount({ searchTerm: f.number }))
                  }}
                >
                  {f.number}
                </span>
              ))}
            </Mui.Box>
          )}
          {leads?.map((lead: any) => {
            if (!lead) return
            const isExpanded = lead.id === selectedLeadId
            const leadState: LeadStatus =
              leadStatus.find((l) => l.state === lead.status) || leadStatus[0]
            // Temp fix
            const vehicleCondition = lead.vehicleCondition
              ? lead.vehicleCondition
              : lead.vehicles.findIndex(
                  (v: Vehicle) => v.vehicleCondition === "running"
                ) !== -1
              ? "running"
              : "not-running"

            const date = new Date(`${lead.moveDate.replace(" ", "T")}`)
            const userTimezoneOffset = date.getTimezoneOffset() * 60000
            const moveDateWithoutTimezone = new Date(
              date.getTime() - userTimezoneOffset
            )
            const usDateString = new Date()
              .toLocaleString("en-CA", { timeZone: "US/Eastern" })
              .split(",")[0]
            const moveDateString = lead.moveDate.split(" ")[0]
            return (
              <div
                key={"List" + lead.id}
                className={`${isExpanded ? "expanded" : ""}`}
                style={{
                  position: "relative",
                  width: "100%",
                  transition: "transform 0.2s ease-out",
                }}
              >
                <Mui.Paper
                  key={"Paper" + lead.id}
                  variant="outlined"
                  sx={{ p: 1.5, borderWidth: 3, borderRadius: 3, mt: 2 }}
                  className={`lead-item ${leadState?.color}`}
                >
                  <Mui.Grid container spacing={1}>
                    <Mui.Grid item md={4}>
                      <div className="details">
                        <span>Agent</span>
                        <span>: {lead.agent?.firstName}</span>
                      </div>
                      <div className="details">
                        <span>Move Date</span>:{" "}
                        {usDateString === moveDateString ? (
                          <span>Today</span>
                        ) : (
                          <span>
                            {moveDateWithoutTimezone.getTime() >
                            new Date(
                              Date.now() + 30 * 24 * 60 * 60 * 1000
                            ).getTime() ? (
                              <time>In a month</time>
                            ) : moveDateWithoutTimezone.getTime() <
                              new Date(
                                Date.now() - 365 * 24 * 60 * 60 * 1000
                              ).getTime() ? (
                              <time>Over a year ago</time>
                            ) : (
                              <ReactTimeAgo
                                date={moveDateWithoutTimezone}
                                locale="en-US"
                              />
                            )}
                          </span>
                        )}
                      </div>
                      <div className="details">
                        <span>Prority</span>:{" "}
                        {leadStatus
                          .filter((l) => l.state === lead.status)
                          ?.map((leadState, idx) => (
                            <span key={"Stat" + idx}>{leadState.message}</span>
                          ))}
                      </div>
                      <Mui.Box marginLeft={-1}>
                        {leadStatus
                          .filter((l) => l.state !== "unclaimed")
                          ?.map((leadState, idx) => (
                            <div
                              key={"Stats" + idx}
                              className="lead-status"
                              onClick={() => {
                                handleUpdateStatus(lead, leadState.state)
                              }}
                            >
                              <div
                                className={`button status-${leadState.color}`}
                              />
                            </div>
                          ))}
                        <div
                          className="lead-status"
                          onClick={() => {
                            handleUpdateOrangeStatus(
                              lead.id,
                              !lead.orangeStatus
                            )
                          }}
                        >
                          <div className={`button status-orange`} />
                        </div>
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <span className="change-date">
                        {`${new Date(
                          lead.createdAt
                        ).toLocaleDateString()} ${new Date(
                          lead.createdAt
                        ).toLocaleTimeString()}`}
                      </span>
                      <Mui.Chip
                        label={
                          <ReactTimeAgo date={lead.createdAt} locale="en-US" />
                        }
                        color="primary"
                        size="small"
                      />

                      {lead.orangeStatus && (
                        <Mui.Button
                          size="small"
                          variant="contained"
                          color="warning"
                        >
                          POSTED BY OTHER
                        </Mui.Button>
                      )}

                      <Mui.Box marginTop={1}>
                        Follow up:{" "}
                        <EdiText
                          showButtonsOnHover
                          type="date"
                          inputProps={{
                            min: currentDate,
                            max: "2030-01-01",
                          }}
                          value={
                            lead.followUp
                              ? new Date(lead.followUp).toLocaleDateString()
                              : "Not Set"
                          }
                          onSave={function (val) {
                            if (val === "Not Set") return
                            return handleUpdateFollowUp(
                              lead.id,
                              new Date(
                                new Date(`${val} 00:00:00`).toUTCString()
                              ).getTime()
                            )
                          }}
                          onCancel={function (val) {
                            if (val === "Not Set") return
                            return handleUpdateFollowUp(lead.id, null)
                          }}
                        />
                      </Mui.Box>
                      <Mui.Box marginTop={1}>
                        <Mui.Stack direction="row" spacing={1}>
                          <Mui.Button
                            variant="contained"
                            endIcon={<Icons.Visibility />}
                            onClick={() => {
                              handleOpenNotes(lead)
                              getLeadNoteHistory(lead)
                            }}
                          >
                            Notes
                          </Mui.Button>
                          <Mui.Button
                            variant="contained"
                            onClick={() => {
                              getLeadNoteHistory(lead)
                              handleOpenHistory(lead)
                            }}
                          >
                            <Icons.History />
                          </Mui.Button>
                          {lead?.IsopenEmail && (
                            <Tooltip
                              title={
                                lead &&
                                lead.webhook &&
                                lead.webhook.length > 0 ? (
                                  <ReactTimeAgo
                                    date={
                                      lead.webhook[lead.webhook.length - 1]
                                        ?.timestamp * 1000
                                    }
                                    locale="en-US"
                                  />
                                ) : (
                                  "No timestamp available"
                                )
                              }
                              arrow
                            >
                              <Mui.Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  handleOpenEmails(lead.webhook)
                                }}
                              >
                                <Icons.EmailOutlined />
                              </Mui.Button>
                            </Tooltip>
                          )}
                        </Mui.Stack>
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <div>
                        <Mui.Button>
                          <img
                            src={map}
                            width="100%"
                            onClick={function () {
                              setOrigin(`${lead.originZip} ${lead.originCity}`)
                              setDestination(
                                `${lead.destinationZip} ${lead.destinationCity}`
                              )
                              handleClickOpenMap()
                            }}
                          />
                        </Mui.Button>
                      </div>
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      md={3.5}
                      xs={12}
                      display="flex"
                      alignItems="center"
                    >
                      <button
                        type="button"
                        className={`expand-button${
                          isExpanded ? " active" : ""
                        }`}
                        onClick={() => {
                          setSelectedLeadId(!isExpanded ? lead.id : null)
                        }}
                      >
                        <Icons.Add />
                      </button>
                      <div className={`status-label status-${leadState.color}`}>
                        {lead.number}
                        {/* TODO: make uniqe id */}
                      </div>
                      <div
                        className={`vehicle-condition ${vehicleCondition} ${lead.shipVia.replace(
                          " ",
                          ""
                        )}`}
                      >
                        <Icons.DirectionsCar />
                      </div>
                      <Mui.Box marginLeft={2} fontWeight="bold">
                        <div>
                          {lead.firstName} {lead.lastName}
                        </div>
                        <a
                          className={
                            lead?.wrong_number
                              ? "text-ustu-cizili phone-number"
                              : "phone-number"
                          }
                          onClick={() => {
                            handleUpdateCall(lead, "true", lead?.wrong_number)
                          }}
                          href={`${
                            !lead?.wrong_number
                              ? "tel:" + lead.phoneNumber
                              : "#"
                          }`}
                        >
                          {phoneFormat(lead.phoneNumber)}
                        </a>
                        {lead.calling && <Icons.Phone />}
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      md={4.5}
                      xs={12}
                      display="flex"
                      alignItems="center"
                    >
                      <Mui.Box display="flex" alignItems="center">
                        <Mui.Button
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            navigator.clipboard.writeText(lead.originZip)
                          }}
                        >
                          {lead.originCity}
                          {","} {lead.originState} {lead.originZip}{" "}
                        </Mui.Button>
                        <Icons.ArrowRightAlt />
                        <Mui.Button
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            navigator.clipboard.writeText(lead.destinationZip)
                          }}
                        >
                          {lead.destinationCity}
                          {","} {lead.destinationState} {lead.destinationZip}{" "}
                        </Mui.Button>
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      md={4}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Mui.Box display="flex" alignItems="center">
                        {/* TODO: make a function */}
                        <a
                          href={`https://app.centraldispatch.com/search?search=~(vC~(mn~1)~tLPW~2~opt~%27all~ofst~0~lmt~50~sF~(~(n~%27pup~d~%27ASC)~(n~%27dMA~d~%27ASC))~dBS~false~sPSO~false~sTOT~false~rT~%27Open~l~(~(c~%27${lead.originCity}~st~%27${lead.originState}~r~25~s~%27Pickup)~(c~%27${lead.destinationCity}~st~%27${lead.destinationState}~r~25~s~%27Dropoff)))`}
                          target="_blank"
                          className="mile-button"
                          rel="noreferrer"
                        >
                          <Icons.KeyboardArrowRight />
                        </a>
                        <a
                          href={`https://app.centraldispatch.com/search?search=~(vC~(mn~1)~tLPW~2~opt~%27all~ofst~0~lmt~50~sF~(~(n~%27pup~d~%27ASC)~(n~%27dMA~d~%27ASC))~dBS~false~sPSO~false~sTOT~false~rT~%27Open~l~(~(c~%27${lead.originCity}~st~%27${lead.originState}~r~50~s~%27Pickup)~(c~%27${lead.destinationCity}~st~%27${lead.destinationState}~r~50~s~%27Dropoff)))`}
                          target="_blank"
                          className="mile-button"
                          rel="noreferrer"
                        >
                          <Icons.KeyboardDoubleArrowRight />
                        </a>
                        <a
                          href={`https://app.centraldispatch.com/search?search=~(vC~(mn~1)~tLPW~2~opt~%27all~ofst~0~lmt~50~sF~(~(n~%27pup~d~%27ASC)~(n~%27dMA~d~%27ASC))~dBS~false~sPSO~false~sTOT~false~rT~%27Open~l~(~(c~%27${lead.originCity}~st~%27${lead.originState}~r~100~s~%27Pickup)~(c~%27${lead.destinationCity}~st~%27${lead.destinationState}~r~100~s~%27Dropoff)))`}
                          target="_blank"
                          className="mile-button"
                          rel="noreferrer"
                        >
                          <KeyboardTripleArrowRight />
                        </a>
                      </Mui.Box>

                      <h1 className="change-date">
                        Updated{" "}
                        <ReactTimeAgo date={lead.updatedAt} locale="en-US" />
                      </h1>
                      <Mui.Button
                        variant="contained"
                        onClick={() => {
                          lead.status === "unclaimed"
                            ? handleUpdateStatus(
                                lead,
                                lead.status === "unclaimed"
                                  ? "claimed"
                                  : "reclaim"
                              )
                            : handleOpenReclaim(lead)
                        }}
                      >
                        {leadState.actionButtonText}
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                  {isExpanded && (
                    <LeadDetail key={"lead" + lead.id} id={lead.id} />
                  )}
                </Mui.Paper>
              </div>
            )
          })}
        </div>
      </div>
      {!loading && leads?.length > 0 && (
        <Stack
          spacing={2}
          sx={{
            width: "100%",
            margin: "auto",
            marginTop: 3,
            marginBottom: 3,
            bottom: 0,
            background: "rgba(255,255,255,0.8)",
            padding: "7px",
          }}
        >
          <Pagination
            count={Math.ceil(leadsCount / 50)}
            page={curPage}
            shape="rounded"
            onChange={handleChangePage}
            size="large"
            color="primary"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Stack>
      )}
      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openMap}
        onClose={handleCloseMap}
      >
        <Mui.DialogTitle>Maps</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            <GoogleMapsWithProps org={originCity} dest={destinationCity} />
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseMap}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {/* TODO: move to a component */}
      <Mui.Dialog open={openReclaim.isOpen} onClose={handleCloseReclaim}>
        <Mui.DialogTitle id="alert-dialog-title">
          {"Are you sure that you want to send a reclaim request?"}
        </Mui.DialogTitle>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseReclaim}>Close</Mui.Button>
          <Mui.Button onClick={handleSubmitReclaim}>Submit</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {/* TODO: move to a component */}
      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={midWidth}
        open={openNotes.isOpen}
        onClose={handleCloseNotes}
      >
        <Mui.DialogTitle>Notes</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.Box>
            <Mui.List className="notelist">
              {selectedLead &&
                selectedLead?.notes
                  ?.map((note: Notes) => (
                    <>
                      <Mui.ListItem key={"Note" + selectedLead?.id + note?.id}>
                        <Mui.Grid container spacing={1} alignItems="center">
                          <Mui.Grid item md={9}>
                            <Mui.ListItemText primary={note.note} />
                          </Mui.Grid>
                          <Mui.Grid item md={3} textAlign="right">
                            <Mui.FormHelperText>
                              <Mui.Box>
                                <b>
                                  {note?.agent?.firstName
                                    ? note?.agent?.firstName
                                    : "Deleted User"}
                                </b>
                              </Mui.Box>
                              {new Date(note.updatedAt).toLocaleDateString()}{" "}
                              {new Date(note.updatedAt).toLocaleTimeString()}
                            </Mui.FormHelperText>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.ListItem>
                      <Mui.Divider />
                    </>
                  ))
                  .reverse()}
            </Mui.List>
            {/* TODO: implement react hook forms */}
            <Mui.TextField
              className="notearea"
              label="Note"
              variant="filled"
              multiline
              fullWidth
              placeholder={note}
              onBlur={(e: any) => {
                setNote(e.target.value)
              }}
            />
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseNotes}>Close</Mui.Button>
          <Mui.Button disabled={!note} onClick={handleSubmitNotes}>
            Save
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={midWidth}
        open={openEmailHistory.isOpen}
        onClose={handleCloseEmails}
      >
        <Mui.DialogTitle>Email History</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.Box>
            <Mui.List className="notelist">
              {webHooks &&
                webHooks
                  ?.map((webhook: any) => (
                    <>
                      <Mui.ListItem
                        key={"Note" + webhook?.id + webhook?.createdAt}
                      >
                        <Mui.Grid container spacing={1} alignItems="center">
                          <Mui.Grid item md={9}>
                            <Mui.ListItemText primary={webhook.event} />
                          </Mui.Grid>
                          <Mui.Grid item md={3} textAlign="right">
                            <Mui.FormHelperText>
                              {webhook.timestamp
                                ? `${new Date(
                                    webhook.timestamp * 1000
                                  ).toLocaleDateString()} ${new Date(
                                    webhook.timestamp * 1000
                                  ).toLocaleTimeString()}`
                                : "Invalid date"}
                            </Mui.FormHelperText>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.ListItem>
                      <Mui.Divider />
                    </>
                  ))
                  .reverse()}
            </Mui.List>
            {/* TODO: implement react hook forms */}
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseEmails}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      {/* TODO: move to a component */}
      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={midWidth}
        open={openHistory.isOpen}
        onClose={handleCloseHistory}
        PaperProps={{ style: { maxHeight: "70vh" } }}
      >
        <Mui.DialogTitle>History</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.Box>
            <Mui.List className="historylist">
              {selectedLead &&
                selectedLead?.histories
                  ?.map((history) => (
                    <>
                      <Mui.ListItem key={"History" + history.id}>
                        <Mui.Grid container spacing={1} alignItems="center">
                          <Mui.Grid item md={9}>
                            <Mui.ListItemText primary={history.message} />
                          </Mui.Grid>
                          <Mui.Grid item md={3} textAlign="right">
                            <Mui.FormHelperText>
                              {new Date(history.updatedAt).toLocaleDateString()}{" "}
                              {new Date(history.updatedAt).toLocaleTimeString()}
                            </Mui.FormHelperText>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.ListItem>
                      <Mui.Divider />
                    </>
                  ))
                  .reverse()}
            </Mui.List>
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseHistory}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  )
}
