/* eslint-disable */
import { Lead } from "../../../types"
import * as Mui from "@mui/material"

type LeadDetailProps = {
  lead: Lead
}

export default function LeadDetailTable(props: LeadDetailProps) {
  const { lead } = props
  const data: Lead = lead

  return (
    <Mui.Card variant="outlined">
      <Mui.CardContent>
        <div className="lead-details">
          <div className="details-row">
            <span>Name:</span>
            <span>{data.firstName + " " + data.lastName}</span>
          </div>
          <div className="details-row">
            <span>Email:</span>
            <span>{data.email}</span>
          </div>
          <div className="details-row">
            <span>Phone:</span>
            <span>{data.phoneNumber}</span>
          </div>
          <div className="details-row">
            <span>Origin:</span>
            <span>
              {data.originCity}, {data.originState} {data.originZip}
            </span>
          </div>
          <div className="details-row">
            <span>Destination:</span>
            <span>
              {data.destinationCity}, {data.destinationState}{" "}
              {data.destinationZip}
            </span>
          </div>
          <div className="details-row">
            <span>Move Date:</span>
            <span>{data.moveDate}</span>
          </div>
          <div className="details-row">
            <span>Total Price:</span>
            <span>{data.priceOffered} $</span>
          </div>
          <div className="details-row">
            <span>Broker Fee:</span>
            <span>{data.priceBroked} $</span>
          </div>
        </div>
      </Mui.CardContent>
    </Mui.Card>
  )
}
