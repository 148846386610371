import React, { useEffect } from "react"
import { useState } from "react"
import "../index.scss"
import * as Mui from "@mui/material"
import * as Icons from "@mui/icons-material"
import { useSelector, useDispatch } from "react-redux"
import {
  selectLeadById,
  updateLead,
  sendMail,
  sendSms,
  calculatePrice,
  selectLoadingStatus,
} from "../leadSlice"
import { Lead } from "../../../types"
import EdiText from "react-editext"
import dayjs, { Dayjs } from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { getEmailTemplates } from "../../Email/emailSlice"
import { RootState } from "../../../app/store"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import { getSmsTemplates } from "../../Sms/smsSlice"
import he from "he"

type LeadDetailProps = {
  id: string
}

interface SmsContent {
  text: string
  to: string
  from: string | undefined
  agentName: string | undefined
  agentNumber: string | undefined
  customerName: string
  vehicles: string
  price: string
  leadId: string
}

export default function LeadDetail(props: LeadDetailProps) {
  const { id } = props
  const data: Lead = useSelector((state) => selectLeadById(state, id))
  const emailTemplates = useSelector(
    (state: RootState) => state.email.templates
  )
  const smsTemplates = useSelector((state: RootState) => state.sms.templates)
  const loading = useSelector(selectLoadingStatus)
  const { userInfo } = useSelector((state: RootState) => state.user)

  const replacePlaceholders = (
    text: string,
    data: Record<string, any>
  ): string => {
    const vehicleList = data?.vehicles
      ?.map(
        (vehicle: any) => `${vehicle.year} ${vehicle.make} ${vehicle.model}`
      )
      .join(", ")

    const vehicleInops = data?.vehicles
      ?.map((vehicle: any) => `${vehicle.vehicleCondition}`)
      .join(", ")

    const date = new Date(data.availableDate)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    const formattedDate = `${month}-${day}-${year}`
    const modifiedHtmlContent = text
      .replace(/~firstName~/g, data.firstName)
      .replace(/~lastName~/g, data.lastName)
      .replace(/~vehicle_list~/g, vehicleList)
      .replace(/~quote_id~/g, data.number)
      .replace(/~origin_city~/g, data.originCity)
      .replace(/~origin_state~/g, data.originState)
      .replace(/~origin_zip~/g, data.originZip)
      .replace(/~destination_city~/g, data.destinationCity)
      .replace(/~destination_state~/g, data.destinationState)
      .replace(/~destination_zip~/g, data.destinationZip)
      .replace(/~vehicle_inop~/g, vehicleInops)
      .replace(/~carrier_type~/g, data.shipVia)
      .replace(/~available_date~/g, formattedDate)
      .replace(/~price~/g, `${data.priceOffered}`)
      .replace(/~order_source~/g, data.provider)
      .replace(
        /~texting_shortlink~/g,
        `https://safemileautotransport.com/continue-booking?q=${data.bookingLink}`
      )
      .replace(/~my_name~/g, userInfo.firstName)
      .replace(/~my_lastname~/g, userInfo.lastName)
      .replace(/~my_email~/g, userInfo.emailAddress)
      .replace(/~my_mobile~/g, userInfo.phoneNumber)

    return modifiedHtmlContent
  }

  const previewInPopup = () => {
    const popupWidth = 600
    const popupHeight = 800
    const popupFeatures = `width=${popupWidth},height=${popupHeight},resizable=yes,scrollbars=yes`
    const newWindow = window.open("", "_blank", popupFeatures)
    const Tempi = he.decode(selectedTemplate.content)
    const datam: Record<string, any> = data
    const Content = replacePlaceholders(Tempi, datam)
    newWindow.document.write(Content)
  }
  const dispatch = useDispatch<any>()
  const [openForm, setOpenForm] = useState(false)
  const [openMail, setOpenMail] = useState(false)
  const [openSms, setOpenSms] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<any>({})
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState("")
  const [mailSubject, setMailSubject] = useState(
    `Your Car Shipping Quote  #${data.number}`
  )
  const [smsContent, setSmsContent] = useState<SmsContent>({
    text: "",
    to: data.phoneNumber,
    from: data.agent?.smsProviderId,
    agentName: data.agent?.firstName,
    agentNumber: data.agent?.phoneNumber,
    customerName: data.firstName,
    vehicles: `${data.vehicles[0].make} ${data.vehicles[0].model}`,
    price: data.priceOffered,
    leadId: data.id,
  })
  const [defaultMoveDate, setDefaultMoveDate] = useState(
    data.moveDate.slice(0, 11)
  )

  const [editVehicle, setEditVehicle] = useState({})
  const [vehiclesValue] = useState(data.vehicles)
  const handleClickOpen = () => {
    setOpenForm(true)
  }
  const handleClose = () => {
    setOpenForm(false)
  }
  const handleSave = () => {
    dispatch(updateLead({ leadId: id, ...formData }))
    setOpenForm(false)
    setFormData({})
  }
  const handleClickOpenMail = () => {
    setOpenMail(true)
  }
  const handleClickCloseMail = () => {
    setOpenMail(false)
  }

  const handleWrongNumber = () => {
    dispatch(
      updateLead({ leadId: id, wrong_number: data?.wrong_number === 1 ? 0 : 1 })
    )
  }

  const handleClickSendMail = () => {
    const postData = {
      ...data,
      vehicleCondition: data.vehicles[0].vehicleCondition,
      bookingLink: `https://safemileautotransport.com/continue-booking?q=${data.bookingLink}`,
      moveDate: dayjs(data.moveDate.slice(0, 11)).format("MM-DD-YYYY"),
      template: selectedTemplate,
    }
    const sentData = { data: postData, subject: mailSubject }
    dispatch(sendMail(sentData))
    setOpenMail(false)
  }
  const handleClickOpenSms = () => {
    setSelectedSmsTemplate("")
    setSmsContent({
      text: ``,
      to: data.phoneNumber,
      from: data.agent?.smsProviderId,
      agentName: data.agent?.firstName,
      agentNumber: data.agent?.phoneNumber,
      customerName: data.firstName,
      vehicles: `${data.vehicles[0].make} ${data.vehicles[0].model}`,
      price: data.priceOffered,
      leadId: data.id,
    })

    setOpenSms(true)
  }

  const setSelectedT = (e: any) => {
    console.log(e)
    setSelectedTemplate(e)
    const datam: Record<string, any> = data
    setMailSubject(replacePlaceholders(e.subject, datam))
    console.log(e)
  }

  const setSelectedSmsT = (e: any) => {
    setSelectedSmsTemplate(e)
    let transformedText = ""
    if (e) {
      const templateText = e.content
      const datam: Record<string, any> = data
      transformedText = replacePlaceholders(templateText, datam)
    }

    setSmsContent({
      text: transformedText,
      to: data.phoneNumber,
      from: data.agent?.smsProviderId,
      agentName: data.agent?.firstName,
      agentNumber: data.agent?.phoneNumber,
      customerName: data.firstName,
      vehicles: `${data.vehicles[0].make} ${data.vehicles[0].model}`,
      price: data.priceOffered,
      leadId: data.id,
    })
  }

  const handleClickCloseSms = () => {
    setOpenSms(false)
  }
  const handleClickSendSms = () => {
    dispatch(sendSms(smsContent))
    setOpenSms(false)
  }
  const handleUpdatePriceOffered = (id: string, priceOffered: string) => {
    dispatch(updateLead({ leadId: id, priceOffered }))
  }
  const handleUpdatePriceBroked = (id: string, priceBroked: string) => {
    dispatch(updateLead({ leadId: id, priceBroked }))
  }
  const [formData, setFormData] = useState({})

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target
    setFormData({ ...formData, [id]: value })
  }

  const handleMoveDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      if (newValue.isValid()) {
        setDefaultMoveDate(newValue.format("YYYY-MM-DD") + " 00:00:00")
        setFormData({
          ...formData,
          moveDate: newValue.format("YYYY-MM-DD") + " 00:00:00",
        })
      }
    }
  }
  useEffect(() => {
    dispatch(getEmailTemplates())
    dispatch(getSmsTemplates())
  }, [dispatch])

  const handleVehicle = (index: number, event: any) => {
    const name = event.target.name
    const value = event.target.value
    if (Object.keys(editVehicle).length === 0)
      Object.assign(editVehicle, vehiclesValue[index])
    setEditVehicle({
      ...editVehicle,
      [name]: value,
    })
    setFormData({
      ...formData,
      vehicles: [
        {
          ...editVehicle,
          [name]: value,
        },
      ],
    })
  }
  const handleSelectbox = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }
  const vehicleCondition = data.vehicleCondition
    ? data.vehicleCondition
    : data.vehicles.findIndex((v) => v.vehicleCondition === "running") !== -1
    ? "running"
    : "not-running"

  const handleCalculate = () => {
    dispatch(
      calculatePrice({
        originZip: data.originZip,
        destinationZip: data.destinationZip,
        num_vehicles: 1,
        v_op: 1,
        v_size: "Car",
        transport: "open",
        leadId: data.id,
      })
    )
  }

  return (
    <div className="lead-detail">
      <Mui.Button
        className="edit-button"
        size="small"
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<Icons.Edit />}
      >
        Edit
      </Mui.Button>
      <Mui.Grid container>
        <Mui.Grid item md={4}>
          <div className="details-row">
            <span>Name</span>
            <span>{data.firstName + " " + data.lastName}</span>
          </div>
          <div className="details-row">
            <span>E-mail</span>
            <span>{data.email}</span>
          </div>
          <div className="details-row">
            <Mui.Button
              variant="outlined"
              color={data?.wrong_number ? "success" : "error"}
              size="small"
              onClick={handleWrongNumber}
            >
              {data?.wrong_number ? "Mark as Valid" : "Mark as Invalid"}
            </Mui.Button>
            <span>Phone</span>
            <span className={data?.wrong_number ? "text-ustu-cizili" : ""}>
              {data.phoneNumber}
            </span>
          </div>
        </Mui.Grid>

        <Mui.Grid item md={3}>
          <div className="details-row vehicle-list">
            <span>Vehicles</span>
            {data?.vehicles?.map((vehicle, i) => (
              <div key={i}>
                <a
                  href={`https://www.google.com/search?tbm=isch&q=${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>
                    {vehicle.year} {vehicle.make} {vehicle.model}
                  </b>
                </a>
              </div>
            ))}
          </div>
          <div className="details-row">
            <span>Vehicle Condition</span>
            <span>{vehicleCondition}</span>
          </div>
          <div className="details-row">
            <span>Carrier Type</span>
            <span>{data.shipVia}</span>
          </div>
        </Mui.Grid>
        <Mui.Grid item md={3}>
          <div className="details-row">
            <span>From</span>
            <span>
              {data.originCity}
              {","} {data.originState} {data.originZip}{" "}
            </span>
          </div>
          <div className="details-row">
            <span>To</span>
            <span>
              {data.destinationCity}
              {","} {data.destinationState} {data.destinationZip}{" "}
            </span>
          </div>
          <div className="details-row">
            <span>Move Date</span>
            <span>
              {dayjs(data.moveDate.slice(0, 11)).format("MM-DD-YYYY")}
            </span>
          </div>
        </Mui.Grid>
        <Mui.Grid item md={2}>
          <div className="details-row">
            <span>Total Price</span>
            <span>
              <EdiText
                showButtonsOnHover
                type="number"
                value={`${data.priceOffered} $`}
                onSave={function (val) {
                  return handleUpdatePriceOffered(data.id, val)
                }}
              />
            </span>
            <Mui.Button
              variant="contained"
              onClick={handleCalculate}
              disabled={loading}
            >
              {loading ? "Calculating..." : "Calculate"}
            </Mui.Button>
            {data.finalPrice && (
              <div className="details-row" style={{ marginTop: 20 }}>
                <span>Recommended Price</span>
                <span>{data.finalPrice} $</span>
              </div>
            )}
          </div>
          <div className="details-row">
            <span>Broker Fee</span>
            <span>
              <EdiText
                showButtonsOnHover
                type="number"
                value={`${data.priceBroked} $`}
                onSave={function (val) {
                  return handleUpdatePriceBroked(data.id, val)
                }}
              />
            </span>
          </div>
          <div className="details-row">
            <Mui.Button variant="contained" onClick={handleClickOpenMail}>
              Send Mail
            </Mui.Button>
          </div>
          <div className="details-row">
            <Mui.Button variant="contained" onClick={handleClickOpenSms}>
              Send SMS
            </Mui.Button>
          </div>
        </Mui.Grid>
      </Mui.Grid>
      {/* TODO: Make component */}
      <form
        onSubmit={(event) => {
          event.preventDefault()
          setFormData(formData)
        }}
      >
        <Mui.Dialog open={openForm} onClose={handleClose}>
          <Mui.DialogTitle>Save</Mui.DialogTitle>
          <Mui.DialogContent>
            <Mui.Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Mui.TextField
                  id="firstName"
                  label="First Name"
                  defaultValue={data.firstName}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="lastName"
                  label="Last Name"
                  defaultValue={data.lastName}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="email"
                  label="email"
                  defaultValue={data.email}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="phoneNumber"
                  label="phone number"
                  defaultValue={data.phoneNumber}
                  onChange={handleInputChange}
                />
                <Mui.FormControl sx={{ m: 1, minWidth: 224 }}>
                  <Mui.InputLabel id="demo-simple-select-helper-label">
                    Carrier Tpye
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="demo-simple-select-helper-label"
                    name="shipVia"
                    defaultValue={data.shipVia}
                    label="Carrier Tpye"
                    onChange={handleSelectbox}
                  >
                    <Mui.MenuItem value={"open"}>Open</Mui.MenuItem>
                    <Mui.MenuItem value={"enclosed"}>Enclosed</Mui.MenuItem>
                  </Mui.Select>
                </Mui.FormControl>
                <Mui.FormControl sx={{ m: 1, minWidth: 224 }}>
                  <Mui.InputLabel id="demo-simple-select-helper-label">
                    Vehicle Condition
                  </Mui.InputLabel>
                  <Mui.Select
                    labelId="demo-simple-select-helper-label"
                    name="vehicleCondition"
                    defaultValue={vehicleCondition}
                    label="Vehicle Condition"
                    onChange={handleSelectbox}
                  >
                    <Mui.MenuItem value={"not-running"}>
                      Not Running
                    </Mui.MenuItem>
                    <Mui.MenuItem value={"running"}>Running</Mui.MenuItem>
                  </Mui.Select>
                </Mui.FormControl>
                {data?.vehicles?.map((vehicle, i) => (
                  <>
                    <Mui.TextField
                      key={`make${i}`}
                      id={`make${i}`}
                      name="make"
                      label={`Vehicle Make ${i}`}
                      defaultValue={vehicle.make}
                      onChange={(e) => {
                        handleVehicle(i, e)
                      }}
                    />
                    <Mui.TextField
                      key={`model${i}`}
                      id={`model${i}`}
                      name="model"
                      label={`Vehicle Model ${i}`}
                      defaultValue={vehicle.model}
                      onChange={(e) => {
                        handleVehicle(i, e)
                      }}
                    />
                    <Mui.TextField
                      key={`year${i}`}
                      id={`year${i}`}
                      name="year"
                      label={`Vehicle Year ${i}`}
                      defaultValue={vehicle.year}
                      onChange={(e) => {
                        handleVehicle(i, e)
                      }}
                    />
                  </>
                ))}
                <Mui.TextField
                  id="originCity"
                  label="Origin City"
                  defaultValue={data.originCity}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="originState"
                  label="Origin State"
                  defaultValue={data.originState}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="originZip"
                  label="Origin Zip"
                  defaultValue={data.originZip}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="destinationCity"
                  label="Destination City"
                  defaultValue={data.destinationCity}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="destinationState"
                  label="Destination State"
                  defaultValue={data.destinationState}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="destinationZip"
                  label="Destination Zip"
                  defaultValue={data.destinationZip}
                  onChange={handleInputChange}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Move Date"
                    inputFormat="MM/DD/YYYY"
                    value={defaultMoveDate}
                    onChange={handleMoveDateChange}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <br />
                <Mui.TextField
                  id="priceOffered"
                  label="Total Price"
                  defaultValue={data.priceOffered}
                  onChange={handleInputChange}
                />
                <Mui.TextField
                  id="priceBroked"
                  label="Broker Fee"
                  defaultValue={data.priceBroked}
                  onChange={handleInputChange}
                />
              </div>
            </Mui.Box>
          </Mui.DialogContent>
          <Mui.DialogActions>
            <Mui.Button onClick={handleClose}>Cancel</Mui.Button>
            <Mui.Button type="submit" onClick={handleSave}>
              Save
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      </form>

      {/* TODO: Make component */}
      <Mui.Dialog open={openMail} fullWidth onClose={handleClickCloseMail}>
        <Mui.DialogTitle>Send Email</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.FormControl fullWidth disabled variant="standard">
            <Mui.TextField
              id="standard-read-only-input"
              defaultValue={"To: " + data.email}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              margin="normal"
            />
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <Select
                value={selectedTemplate}
                onChange={(e) => setSelectedT(e.target.value)}
                displayEmpty
                fullWidth
                autoWidth
              >
                <MenuItem value="" disabled>
                  Select Template
                </MenuItem>
                {emailTemplates?.map((template: any) => {
                  return (
                    <MenuItem
                      style={{ width: "100%" }}
                      key={"MailTemp" + template.id}
                      value={template}
                    >
                      {template.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Mui.TextField
              fullWidth
              multiline
              id="fullWidth"
              InputProps={{
                startAdornment: (
                  <Mui.InputAdornment position="start">
                    Subject:
                  </Mui.InputAdornment>
                ),
              }}
              value={mailSubject}
              onChange={(e: any) => {
                setMailSubject(e.target.value)
              }}
            />
          </Mui.FormControl>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button disabled={!selectedTemplate} onClick={previewInPopup}>
            Preview
          </Mui.Button>
          <Mui.Button onClick={handleClickCloseMail}>Close</Mui.Button>
          <Mui.Button
            disabled={!selectedTemplate}
            onClick={handleClickSendMail}
          >
            Send Mail
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      {/* TODO: Make component */}
      <Mui.Dialog open={openSms} onClose={handleClickCloseSms}>
        <Mui.DialogTitle>Send SMS</Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText>
            Please enter your sms content to textarea. SMS will be send
            automatically
          </Mui.DialogContentText>
          <Mui.FormControl fullWidth variant="standard">
            <Mui.TextField
              id="standard-read-only-input"
              defaultValue={"To: " + data.phoneNumber}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
          </Mui.FormControl>

          <FormControl
            fullWidth
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Select
              value={selectedSmsTemplate}
              onChange={(e) => setSelectedSmsT(e.target.value)}
              displayEmpty
              fullWidth
              autoWidth
            >
              <MenuItem value="">Select Sms Template</MenuItem>
              {smsTemplates?.map((smsData: any) => {
                return (
                  <MenuItem
                    style={{ width: "100%" }}
                    key={"SmsTemp" + smsData.id}
                    value={smsData}
                  >
                    {smsData.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <Mui.TextField
            fullWidth
            multiline
            id="fullWidth"
            value={smsContent?.text}
            onChange={(e: any) => {
              setSmsContent({
                text: e.target.value,
                to: data.phoneNumber,
                from: data.agent?.smsProviderId,
                agentName: data.agent?.firstName,
                agentNumber: data.agent?.phoneNumber,
                customerName: data.firstName,
                vehicles: `${data.vehicles[0].make} ${data.vehicles[0].model}`,
                price: data.priceOffered,
                leadId: data.id,
              })
            }}
          />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleClickCloseSms}>Close</Mui.Button>
          <Mui.Button onClick={handleClickSendSms}>Send Sms</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </div>
  )
}
