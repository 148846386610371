import { store } from "../../app/store"
import * as Mui from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { LoginForm } from "../../types"
import { createTheme } from "@mui/material/styles"
import { userLogin, resetState } from "./userSlice"
import { RootState } from "../../app/store"
import Notification from "../../components/Notification"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import "./index.scss"

type AppDispatch = typeof store.dispatch
const theme = createTheme()

export default function Login() {
  const { register, handleSubmit } = useForm<LoginForm>()
  const dispatch = useDispatch<AppDispatch>()
  const { status, error, auth } = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()

  const submitForm: SubmitHandler<LoginForm> = (data: LoginForm) => {
    if (status !== "loading") {
      dispatch(userLogin(data))
    }
  }
  useEffect(() => {
    if (auth) {
      navigate("/")
    }
  }, [auth, navigate])
  return (
    <>
      {status === "failed" && (
        <Notification
          error={error}
          onClick={() => {
            dispatch(resetState())
          }}
        ></Notification>
      )}
      <Mui.ThemeProvider theme={theme}>
        <div className="coverimage" />
        <Mui.Container className="bg loginform" component="main" maxWidth="xs">
          <Mui.CssBaseline />
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Mui.Box>
              <img className="logo" src={logo} alt="safemile" width={220} />
            </Mui.Box>
            <Mui.Box
              component="form"
              onSubmit={handleSubmit(submitForm)}
              sx={{ mt: 1 }}
            >
              <Mui.TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                {...register("emailAddress")}
              />
              <Mui.TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                {...register("password")}
              />
              <Mui.FormControlLabel
                control={<Mui.Checkbox color="primary" />}
                label="Remember me"
                {...register("rememberMe")}
              />
              <Mui.Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
          {/* <Mui.Typography
            sx={{ mt: 8, mb: 4 }}
            variant="body2"
            color="text.secondary"
            align="center">
            {'Copyright © '}
            <Mui.Link color="inherit" href="https://safemileautotransport.com/">
              Safemile CRM
            </Mui.Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Mui.Typography> */}
        </Mui.Container>
      </Mui.ThemeProvider>
    </>
  )
}
