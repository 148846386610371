import * as Mui from "@mui/material"
import * as Icons from "@mui/icons-material"

type Props = {
  error: string
  onClick: () => void
}

export default function Notification(props: Props) {
  const { error, onClick } = props
  return (
    <Mui.Box className="notification">
      <Mui.Alert severity="error">{error}</Mui.Alert>
      <Icons.Close className="close" onClick={onClick} />
    </Mui.Box>
  )
}
