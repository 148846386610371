import { Component } from "react"
import * as Mui from "@mui/material"
import GoogleMapReact from "google-map-react"
import { Typography } from "@mui/material"
import "./index.scss"
/* eslint-disable */
type mapsProps = { org: any; dest: any }
type mapsState = {
  distance: string | undefined
  duration: string | undefined
}
//let distance: string | undefined

//result?.routes[0].legs[0].distance?.text
class GoogleMaps extends Component<mapsProps, mapsState> {
  constructor(props: any) {
    super(props)
  }
  state: mapsState = {
    distance: "0",
    duration: "0",
  }

  render() {
    /* TODO Change ANY */
    const apiIsLoaded = (map: any | null, maps: any) => {
      const directionsService = new google.maps.DirectionsService()
      const directionsRenderer = new google.maps.DirectionsRenderer()
      directionsRenderer.setMap(map)

      directionsService.route(
        {
          origin: this.props.org,
          destination: this.props.dest,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result)
            this.setState((state) => ({
              distance: result?.routes[0].legs[0].distance?.text,
              duration: result?.routes[0].legs[0].duration?.text,
            }))
          } else {
            console.error(`error fetching directions ${result}`)
          }
        }
      )
    }
    const displayTimeAndDistance = {
      distance: this.state.distance,
      duration: this.state.duration,
    }

    return (
      /*TODO solve console log warning/error using theme typography */
      <Typography variant="body2" component="span">
        <Mui.Grid
          style={{ height: "400px", width: "100%" }}
          container
          spacing={1}
        >
          <Mui.Grid component={"span"} item xs={12} md={6} className="mapinfo">
            <span>Distance:</span>
            <span>{displayTimeAndDistance.distance}</span>
          </Mui.Grid>
          <Mui.Grid item xs={12} md={6} textAlign="right" className="mapinfo">
            <span>Duration:</span>
            <span>{displayTimeAndDistance.duration}</span>
          </Mui.Grid>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAPS_API_KEY as string,
            }}
            defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          />
        </Mui.Grid>
      </Typography>
    )
  }
}
export default GoogleMaps
