import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider } from "react-router-dom"
import route from "./route"
import { store } from "./app/store"
import { Provider } from "react-redux"
import CssBaseline from "@mui/material/CssBaseline"
import "./index.scss"
import SocketProvider from "./components/SocketProvider"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <SocketProvider>
      <CssBaseline />
      <RouterProvider router={route} />
    </SocketProvider>
  </Provider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
