import { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Sidebar from "../../components/Sidebar"
import * as Mui from "@mui/material"
import "./index.scss"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

export default function Layout() {
  const mdTheme = createTheme()

  const [showTopBtn, setShowTopBtn] = useState(false)
  useEffect(() => {
    document.querySelector("main").addEventListener("scroll", () => {
      if (document.querySelector("main").scrollTop > 400) {
        setShowTopBtn(true)
      } else {
        setShowTopBtn(false)
      }
    })
  }, [])

  const goToTop = () => {
    document.querySelector("main").scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Mui.Box sx={{ display: "flex" }}>
        <Sidebar />
        <Mui.Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Mui.Container maxWidth="lg" sx={{ mt: 4 }}>
            <Outlet />
            {showTopBtn && (
              <div className="upBtn">
                <ArrowUpwardIcon
                  onClick={goToTop}
                  className="upBtn-icon"
                  sx={{ fontSize: 30 }}
                />
              </div>
            )}
          </Mui.Container>
        </Mui.Box>
      </Mui.Box>
    </ThemeProvider>
  )
}
