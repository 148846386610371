import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

interface SmsTemplate {
  id?: any
  title: any
  content: any
}

interface SmsState {
  templates: SmsTemplate[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null
}

const initialState: SmsState = {
  templates: [],
  status: "idle",
  error: null,
}

export const getSmsTemplates = createAsyncThunk(
  "sms/fetchTemplates",
  async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/sms/templates`,
      {
        withCredentials: true,
      }
    )
    return data
  }
)

export const addSmsTemplate = createAsyncThunk(
  "sms/addTemplate",
  async (newTemplate: SmsTemplate) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/sms/templates`,
      newTemplate,
      { withCredentials: true }
    )
    return data
  }
)

export const deleteSmsTemplate = createAsyncThunk(
  "sms/deleteTemplate",
  async (templateId: string) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/sms/templates/${templateId}`,
      {
        withCredentials: true,
      }
    )
    return templateId
  }
)

const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSmsTemplates.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getSmsTemplates.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.templates = action.payload
      })
      .addCase(getSmsTemplates.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message ?? "Data could not be fetched."
      })
      .addCase(addSmsTemplate.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addSmsTemplate.fulfilled, (state, action) => {
        state.status = "succeeded"
        const updatedTemplate = action.payload
        const index = state.templates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (index !== -1) {
          state.templates[index] = updatedTemplate
        } else {
          state.templates.push(action.payload)
        }
      })
      .addCase(addSmsTemplate.rejected, (state, action) => {
        state.status = "failed"
        state.error =
          action.error.message ?? "An error occurred while adding the template."
      })
      .addCase(deleteSmsTemplate.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteSmsTemplate.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.templates = state.templates.filter(
          (template) => template.id !== action.payload
        )
      })
      .addCase(deleteSmsTemplate.rejected, (state, action) => {
        state.status = "failed"
        state.error =
          action.error.message ??
          "An error occurred while deleting the template."
      })
  },
})

export default smsSlice.reducer
