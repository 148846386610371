import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

interface EmailTemplate {
  id?: any
  title: any
  subject: any
  content: any
}

interface EmailState {
  templates: EmailTemplate[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null
}

const initialState: EmailState = {
  templates: [],
  status: "idle",
  error: null,
}

export const getEmailTemplates = createAsyncThunk(
  "email/fetchTemplates",
  async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/email/templates`,
      {
        withCredentials: true,
      }
    )
    return data
  }
)

export const addEmailTemplate = createAsyncThunk(
  "email/addTemplate",
  async (newTemplate: EmailTemplate) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/email/templates`,
      newTemplate,
      { withCredentials: true }
    )
    return data
  }
)

export const deleteEmailTemplate = createAsyncThunk(
  "email/deleteTemplate",
  async (templateId: string) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/email/templates/${templateId}`,
      {
        withCredentials: true,
      }
    )
    return templateId
  }
)

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmailTemplates.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getEmailTemplates.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.templates = action.payload
      })
      .addCase(getEmailTemplates.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message ?? "Veri alınamadı."
      })
      .addCase(addEmailTemplate.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addEmailTemplate.fulfilled, (state, action) => {
        state.status = "succeeded"
        const updatedTemplate = action.payload
        const index = state.templates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (index !== -1) {
          state.templates[index] = updatedTemplate
        } else {
          state.templates.push(action.payload)
        }
      })
      .addCase(addEmailTemplate.rejected, (state, action) => {
        state.status = "failed"
        state.error =
          action.error.message ?? "An error occurred while adding the template."
      })
      .addCase(deleteEmailTemplate.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteEmailTemplate.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.templates = state.templates.filter(
          (template) => template.id !== action.payload
        )
      })
      .addCase(deleteEmailTemplate.rejected, (state, action) => {
        state.status = "failed"
        state.error =
          action.error.message ??
          "An error occurred while deleting the template."
      })
  },
})

export default emailSlice.reducer
